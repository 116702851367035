import clsx from "clsx";
import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import TextField from "../../components/ui/TextField";
import { SearchIcon, TelegramEllipse } from "../../assets/Icons";
import { recipientDetails, searchValue, transactionDetails } from "../../store";
import { RecipientDetais } from "../../store/store";
import Typograpy from "../../components/ui/Typography";
import RecipientCard from "../../components/ui/RecipientCard";
import { getUserDocs, getUserRecipients } from "../../services/userDetails";
import { TransactioncancellationReasons } from "../../utils";
import Modal from "../../components/ui/Modal";
import Checkbox from "../../components/ui/Checkbox";
import Button from "../../components/ui/Button";
import { handleTransactionComplete } from "../../services/transaction";
import mixpanel from "mixpanel-browser";

const HomePickRecipient = () => {
  const navigate = useNavigate();
  // const isUseEffectFired = useRef(false);
  const [recipient, setRecipient] = useRecoilState(recipientDetails);
  const transactionData = useRecoilValue(transactionDetails);
  const recipientDetailsData: RecipientDetais = useRecoilValue(recipientDetails);
  // const [recipientDetailsData, setrecipientDetailsData] = React.useState(recipientDetails)
  const [searchInput, setSearchInput] = useRecoilState(searchValue);
  const [filteredRecipient, setFileteredRecipient] =
    React.useState<RecipientDetais>(recipientDetailsData);
  const [isModalOpen, setIsModalOpen] = useState<string>("");
  const [cancellationReasons, setCancellationReasons] = useState<string>("");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isDocVerified, setIsDocVerified] = useState<boolean>(false);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const resetTransactionDetails = useResetRecoilState(transactionDetails);
  const { state } = useLocation();

  const getRecipients = async () => {
    try {
      const response = await getUserRecipients(currentUser);
      const { data } = response;

      setRecipient(data.recipient);
    } catch (error) {
      //TODO : Show error as per the error message providedd
      /* eslint-disable-next-line */
      console.error(error);
    }
  };

  const getUserDocuments = async () => {
    try {
      const response = await getUserDocs(currentUser);
      const { data } = response;
      setIsDocVerified(data?.isDocVerified);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getUserDocuments();
  }, []);

  React.useEffect(() => {
    if (searchInput) {
      setFileteredRecipient(
        [...recipientDetailsData].filter(
          (el) =>
            el.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
            el.lastName.toLowerCase().includes(searchInput.toLowerCase()),
        ),
      );
    } else if (searchInput === "") {
      setFileteredRecipient(recipientDetailsData);
    }
  }, [searchInput, recipient]);

  React.useEffect(() => {
    getRecipients();
  }, []);

  const handleChoseRecipient = (recipient: string) => {
    isDocVerified && transactionData?.purposeCode
      ? navigate("/user-documents", {
        state: {
          recipientName: recipient
        }
      })
      : navigate("/send-money");
    // sessionStorage.setItem("isRecipientSelected", "true");
  };

  const handleEyeClick = async (e: React.MouseEvent) => {

    e.stopPropagation();
    try {
      navigate("/recipient-profile");
    }
    catch (error) {
      console.error(error);
    }
  };

  const handleNavigate = () => {
    handleTransactionComplete();
    resetTransactionDetails();
    navigate("/");
  };

  return (
    <motion.div
      className="mt-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="grid grid-cols-1 px-6 md:pl-9 md:pr-[85px] md:mt-[54px]">
        <div className="hidden text-sm font-medium text-black md:block mb-[23px]">
          Select your Recipients
        </div>
        <div className="w-full h-12 mb-20 lg:mb-7">
          <TextField
            name="searchRecipient"
            type="text"
            placeholder={
              <div className="flex items-center justify-between gap-2 lg:justify-start">
                <div className="order-2 md:order-1 mt-4">
                  <SearchIcon fillColor="fill-grey-650" />
                </div>
                <span className="order-1 md:ml-4 md:order-2 mt-4">Search Recipient Name</span>
              </div>
            }
            value={searchInput}
            fullWidth
            inputClasses="h-16 !pt-1 bg-white w-full !border-0"
            placeholderStyle={clsx(
              searchInput && "hidden",
              "peer-placeholder-shown:!top-6 !text-sm !text-grey-650 !font-normal text-sm peer-focus:hidden w-full px-6",
            )}
            onChange={(e) => setSearchInput(e.target.value)}
            pattern="[a-zA-Z0-9 ]{1,}"
          />
        </div>
        <div onClick={() => {
          // sessionStorage.setItem("isRecipientSelected", "true");
          mixpanel.track("Add Recipient");
          navigate('/recipient-details', {
            state: {
              fromSendMoney: state?.fromSendMoney
            }
          });
        }}>
          <div className="w-full py-5 md:py-10 pl-8 md:pl-10 flex items-center bg-primary-150 gap-6 md:gap-12 rounded-[6px] mb-4">
            <div className="w-9 h-9 md:w-[56px] md:h-[56px] bg-[transparent] opacity-50 rounded-full border-primary border-[3px] text-xl md:text-[40px] text-primary flex justify-center items-center text-center cursor-pointer">
              <div>+</div>
            </div>

            {/* You might want to adjust the Typograpy component */}
            <Typograpy
              className="text-primary opacity-50 text-base md:text-[30px] font-normal"
              text={"Add a new recipient"}
              tagType="div"
            />
          </div>
        </div>
        {recipientDetailsData?.length === 0 ? (
          <div className="flex flex-col text-center mt-[108px] md:mt-[186px]">
            <Typograpy
              tagType="div"
              className="text-[32px] md:text-[48px] text-primary-600 font-normal mb-9 md:mb-[17px]"
              text="No Recipients found"
            />
            <Typograpy
              tagType="div"
              className="text-singleHistoryText text-[48px] md:text-[72px]"
              text={
                <span>
                  Try the <span className="opacity-50 text-primary-600">“ + ”</span> button above
                </span>
              }
            />
          </div>
        ) : (
          filteredRecipient?.map((el,index) => (
            <div key={index} onClick={() => { handleChoseRecipient(el.firstName); }}>
              <RecipientCard
                key={el.bankAccountNumber}
                firstName={el.firstName}
                lastName={el.lastName}
                coutryOfResidence={el.countryOfResidence}
                accNo={el.bankAccountNumber}
                id={el.id}
                onButtonClick={() => handleChoseRecipient(el.firstName)}
                icon={<TelegramEllipse />}
                onEyeClick={(e: React.MouseEvent) => handleEyeClick(e)}
                type={el?.type}
              />
            </div>
          ))
        )}
      </div>
      {/* Cancel Transaction Modal */}
      <Modal
        header="Reason for cancellation"
        headerClass={"bg-white !my-0 py-6 px-12 font-bold text-lg"}
        modalClass="!p-0 !rounded !overflow-hidden mb-20"
        visible={isModalOpen === "cancelReasons"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="px-5 pb-5">
          {TransactioncancellationReasons.map((data, index) => {
            return (
              <div className="space-x-2 px-7 border-b-[1px] py-6 first:pt-0" key={index}>
                <Checkbox
                  label={data}
                  value={data}
                  name={"cancellationReason"}
                  handleChange={(e) => {
                    setCancellationReasons(e.target.value);
                  }}
                />
              </div>
            );
          })}
          {cancellationReasons === "Other Reason..." && (
            <div className="flex items-center justify-center mt-2">
              <textarea
                name="otherReasonDescription"
                value={otherReason}
                rows={4}
                cols={50}
                placeholder="Write your reason here....."
                onChange={(e) => {
                  setOtherReason(e.target.value);
                }}
                className="w-full p-3 bg-white-100 focus-visible:border-0 placeholder:text-center"
              />
            </div>
          )}
          <div className="flex items-center justify-center mt-7 px-[78px]">
            <Button
              id="Submit-cancellationReasons"
              bgColor={`${cancellationReasons === "" ? "bg-grey-350" : "bg-primary"}`}
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderWidth: "border-none",
                borderRadius: "rounded-[100px]",
              }}
              disabled={cancellationReasons === ""}
              className={"py-3 px-28"}
              onClickHandler={() =>
                cancellationReasons !== "" && cancellationReasons === "Other Reason..."
                  ? otherReason !== "" && handleNavigate()
                  : handleNavigate()
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </motion.div>
  );
};

export default HomePickRecipient;
