/*eslint-disable*/
import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";

import {
  deliveryOptions,
  kycStepperSteps,
  offerHeader,
  offersModalData,
  purposeCodeList,
  purposeCodeListInward,
  receivingCountryData,
  selectPurposeHeader,
  sendCurrencyCountryData,
  stepperSteps,
  TransactioncancellationReasons,
} from "../../utils";
import PaymentDetailsCard from "./PaymentDetailsCard";
import {
  confirmedPurpose,
  currencies,
  enteredPromoCode,
  isEsigned,
  isInwardTransaction,
  purposeCode,
  redeemedHopCoins,
  sendAmountState,
  sendingAmountState,
  transactionDetails,
  userAdditionalDocs,
  userDetails,
} from "../../store";
import { getTransactionSummary } from "../../services/sendMoney";
import SendReceiveMoney from "./SendReceiveMoney";
import SelectPurpose from "./SelectPurpose";
import ApplyPromoCode from "./ApplyPromoCode";
import DeliveryCard from "./DeliveryCard";
import Modals from "./Modals";
import GoBackandStepperSection from "./GoBackandStepperSection";
import { UserDetails } from "../../store/store";
import { getUserDocs } from "../../services/userDetails";
import { getHopCoins } from "../../services/rewards";
import { motion } from "framer-motion";
import mixpanel from "mixpanel-browser";
// import CouponBanner from "../../components/ui/couponBanner";
import { inwardKycStatus, updateNewKycStatus } from "../../services/inward";
import UnderReview from "../userDocuments/KycModals/kycStatusModals/underReview";
import InwardGBPModal from "../userDocuments/KycModals/kycStatusModals/InwardGBPModal";
import KycApproved from "../userDocuments/KycModals/kycStatusModals/kycApproved";
import { RemitLoader } from "../../components/ui/Loader";
import { getCkycStatus } from "../../services/kyc";
import DocumentUpload from "./DocumentUpload";
import { CloseIcon, WarningIcon } from "../../assets/Icons";
// import { RemitLoader } from "../../components/ui/Loader";

const SendMoney = () => {
  const navigate = useNavigate();
  const vendorKey = process.env.REACT_APP_VENDOR_KEY;
  const userData: UserDetails = useRecoilValue(userDetails);
  const [transactionDetailsData, setTransactionDetails] = useRecoilState(transactionDetails);
  const [confirmedPurposeValue, setConfirmedPurpose] = useRecoilState(confirmedPurpose);
  const [esignedStatus, setEsignedStatus] = useRecoilState(isEsigned);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const [selectedDeliveryOption, setSelectedDeliveryOption] =
    React.useState<string>("Standard Delivery");
  const [isModalOpen, setIsModalOpen] = React.useState<string>("");
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [offerSearchInput, setOfferSearchInput] = React.useState<string>("");
  const [sendCurrencyModalData, setSendCurrencyModalData] = React.useState(sendCurrencyCountryData);
  const [offerData, setOfferModalData] = React.useState(offersModalData);
  const [currency, setCurrency] = useRecoilState(currencies);
  const [selectedPurposeCode, setSelectedPurposeCode] = useRecoilState(purposeCode);
  const [selectedInwardPurposeCode, setSelectedInwardPurposeCode] = React.useState<string>("");
  const [selectedOfferCode, setSelectedOfferCode] = React.useState<string>("");
  const [appliedHopCoins, setAppliedHopCoins] = useRecoilState(redeemedHopCoins);
  const [sendingAmount, setSendingAmount] = useRecoilState(sendingAmountState);
  const [receivingAmount, setReceivingAmount] = React.useState<number>(
    transactionDetailsData?.reciepient_recieve ?? 10.17,
  );
  const [onChangeHandlerType, setOnChangeHandleType] = React.useState<string>("send");
  const [cancellationReasons, setCancellationReasons] = React.useState<string>("");
  const [otherReason, setOtherReason] = React.useState<string>("");
  const [isInbound, setIsInbound] = React.useState<boolean>(false);
  const [isTcsPresent, setIsTcsPresent] = React.useState<boolean>(false);
  const [isLoanSanctionLetter, setIsLoanSanctionLetter] = React.useState<boolean>(false);
  const [activeCoins, setActiveCoins] = React.useState<number>(0);
  const [useCoins, setUseCoins] = React.useState<number>(0);
  const [usable, setUsable] = React.useState<boolean>(false);
  const [promoCodeError, setPromoCodeError] = React.useState<string>("");
  const [enteredCode, setEnteredCode] = useRecoilState(enteredPromoCode);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [promoCodeDiscount, setPromoCodeDiscount] = React.useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [promoCodeApplied, setPromoCodeApplied] = React.useState<any>();
  const [purposeError, setPurposeError] = React.useState<boolean>(false);
  const [purposeErrorInward, setPurposeErrorInward] = React.useState<boolean>(false);
  const [isInward, setIsInward] = useRecoilState(isInwardTransaction);
  const [kycStatus, setKycStatus] = React.useState("");
  const [showKycUnderReviewModal, setShowKycUnderReviewModal] = React.useState(false);
  const [showKycVerified, setShowKycVerified] = React.useState(false);
  const [showInwardGBPModal, setShowInwardGBPModal] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [summaryLoading, setSummaryLoading] = React.useState<boolean>(false);
  const [sendAmount, setSendAmount] = useRecoilState(sendAmountState);
  const [isKycModalOpen, setIsKycModalOpen] = useState(false);
  const [isKycCompleted, setIsKycCompleted] = useState(true);
  const [noLoanConfirmationLetter, setNoLoanConfirmationLetter] = useState(false);
  const [, setAdditionalDocUploaded] = useRecoilState(userAdditionalDocs);
  const [promoLoading, setPromoLoading] = useState(false);

  React.useEffect(() => {
    if (transactionDetailsData?.user_send) {
      setSendingAmount(transactionDetailsData?.user_send ?? 5000);
      setSendAmount(transactionDetailsData?.user_send ?? 1000);
    }
  }, []);

  React.useEffect(() => {
    setTransactionDetails({ ...transactionDetailsData, user_send: 1000 });
    // setConfirmedPurpose({ value: "Select Purpose", code: "" });

    setIsInward(false);
    getUserDocs(currentUser)
      .then((response) => {
        if (!response?.data?.isDocVerified) {
          setIsKycCompleted(false);
        } else {
          setIsKycCompleted(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useMemo(() => {
    setAdditionalDocUploaded({
      studentPassport: false,
      offerLetter: false,
      rentNotice: false,
      relationProof: false,
      recipientPassDlssn: false,
      giftDeclaration: false,
      airTicket: false,
      visa: false,
      loanLetter: false,
      recipientPassDLssn: false,
      beneficiaryId: false,
      studentPassportBack: false,
    });
  }, [selectedPurposeCode]);

  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });

  const removePromoCode = () => {
    setSelectedOfferCode("");
  };

  // window.onbeforeunload = function () {
  //   if (window.location.href.includes("send-money")) {
  //     sessionStorage.removeItem("isRecipientSelected");
  //   } else {
  //     sessionStorage.removeItem("isRecipientSelected");
  //   }
  // };

  useEffect(() => {
    // setLoading(true);
    // setLoading(true);
    getHopCoins(currentUser).then((response) => {
      // setLoading(false);
      // setLoading(false);
      setActiveCoins(response?.data?.active_hop_coins);
    });
  }, [useCoins]);

  const sendingCurrencyModalHeader = (
    <div className={clsx("flex justify-center items-center pr-10")}>
      <div className={clsx("w-8 h-8 flex justify-center items-center lg:mr-7 mr-5")}>
        <img
          src={`/images/png/${currency?.sendingCurrency}.png`}
          alt="selectedCurrency-image"
          className="rounded-[200px] w-full h-full"
        />
      </div>
      <div className="mr-6 text-2xl font-bold text-white lg:mr-12">{currency?.sendingCurrency}</div>
    </div>
  );

  const receiveCurrencyModalHeader = (
    <div className={clsx("flex justify-center items-center pr-10")}>
      <div className={clsx("w-8 h-8 flex justify-center items-center mr-5 lg:mr-7")}>
        <img
          src={`/images/png/${currency?.receiveCurrency}.png`}
          alt="selectedCurrency-image"
          className="rounded-[200px] w-full h-full"
        />
      </div>
      <div className="mr-6 text-2xl font-bold text-white lg:mr-12">{currency?.receiveCurrency}</div>
    </div>
  );

  const payTotalAmount = () => {
    console.log("Currency inside confirm btns", currency);
    console.log("PurposeCode inside confirm btns", selectedPurposeCode);
    console.log("ConfirmedPurposeVlaue inside confirm btns", confirmedPurposeValue);
    mixpanel.track("Confirm_sendmoney");
    mixpanel.track("Currency selected_you_send", {
      "Currency Selected": currency?.sendingCurrency,
      "Amount Send": sendingAmount,
    });
    mixpanel.track("Currency selected_they_recieve", {
      "Receiving Currency": currency?.receiveCurrency,
      "Receiving Amount": receivingAmount,
    });
    mixpanel.track("Purpose of payment selected", {
      "Purpose Code": selectedOfferCode,
    });
    if (selectedPurposeCode === "") {
      setPurposeError(true);
    }
    if (!isKycCompleted && selectedPurposeCode !== "") {
      setIsKycModalOpen(true);
    } else {
      setTransactionDetails({
        ...transactionDetailsData,
        isEducation: isLoanSanctionLetter && confirmedPurposeValue.code === "S0305" ? true : false,
      });
      mixpanel.track("Confirm PAY Button Clicked");
      if (isInbound && confirmedPurposeValue.code === "") {
        if (!userData?.inwardUserDetails) {
          inwardKycStatus()
            .then((response) => {
              setKycStatus(response?.data?.status);
            })
            .catch((error) => {
              setError(error?.response?.data?.error?.type);
            });
        } else if (
          userData?.inwardUserDetails.kycStatus == "NEW" ||
          userData?.inwardUserDetails.kycStatus == "PENDING" ||
          userData?.inwardUserDetails.kycStatus == "DOCUMENTS_UPLOADED"
        ) {
          navigate("/userdocuments-inbound");
        } else if (
          userData?.inwardUserDetails.kycStatus == "VERIFIED" ||
          userData?.inwardUserDetails.kycStatus == "ACTIVE"
        ) {
          // window.console.log("userData?.inwardUserDetails.kycStatus",userData?.inwardUserDetails.kycStatus)
          navigate("/pick-recipient", {
            state: {
              fromSendMoney: true,
            },
          });
        } else if (
          userData?.inwardUserDetails.kycStatus == "SUSPENDED" ||
          userData?.inwardUserDetails.kycStatus == "INACTIVE"
        ) {
          //TODO@Chintan : Need to set route
        }
      } else {
        if (
          confirmedPurposeValue.value === "Overseas Education - University Fees" ||
          confirmedPurposeValue.value === "Overseas Education - Living Expenses" ||
          confirmedPurposeValue.value === "Family Maintenance" ||
          confirmedPurposeValue.value === "Business Travel" ||
          confirmedPurposeValue.value === "Private Visits" ||
          confirmedPurposeValue.value === "Personal Gift or Donation"
        ) {
          // navigate("/user-documents");
          //   window.console.log("confirm button clicked");
          //   navigate("/pick-recipient");
          // } else {
          getUserDocs(currentUser)
            .then((response) => {
              // setLoading(false);
              // window.console.log("response?.data",response?.data["isDocVerified"])
              console.log("reponse... inside confrm btn",response)
              if (response?.data["isDocVerified"] && confirmedPurposeValue.code !== "") {
                console.log("here it is... inside confrm btn")
                navigate("/pick-recipient", {
                  state: {
                    fromSendMoney: true,
                  },
                });
                // const isRecipientSelected = sessionStorage.getItem("recipient_id")
                // if(isRecipientSelected){
                //   navigate("/user-documents");
                // }else{
                //   navigate("/pick-recipient", {
                //     state: {
                //       fromSendMoney: true,
                //     },
                //   });
                // }
                // isRecipientSelected === "true" ? navigate("/review") : navigate("/pick-recipient");
              } else {
                confirmedPurposeValue.code !== "" &&
                  // navigate("/user-documents");
                  // navigate("/pick-recipient");
                  setIsKycModalOpen(true);
              }
            })
            .catch((error) => {
              // setLoading(false);
              setError(error?.response?.data?.error?.type);
            });
        }
      }
      // sessionStorage.removeItem("isRecipientSelected");
    }
  };

  useEffect(() => {
    if (selectedInwardPurposeCode !== "") setPurposeErrorInward(false);
    // else setPurposeErrorInward(false);
  }, [selectedInwardPurposeCode]);

  const handleInwardClickHandler = () => {
    if (selectedInwardPurposeCode === "") {
      setPurposeErrorInward(true);
      return;
    } else if (selectedInwardPurposeCode !== "") {
      setPurposeErrorInward(false);
    }

    setTransactionDetails({
      ...transactionDetailsData,
      isEducation: isLoanSanctionLetter && confirmedPurposeValue.code === "S0305" ? true : false,
    });

    inwardKycStatus().then((res: any) => {
      if (res?.data?.status === "VERIFIED" || res?.data?.status === "ACTIVE") {
        navigate("/inward/pick-recipient");
      } else if (
        res?.data?.status === "PENDING" ||
        res?.data?.status === "DOCUMENTS_UPLOADED" ||
        res?.data?.status === "NEW"
      ) {
        setShowKycUnderReviewModal(true);
        // navigate("/pick-recipient");
      } else if (
        res?.data?.status === "AWAITING_FOR_DOCUMENT" ||
        res?.data?.status === "YET_TO_START"
      ) {
        // navigate("/inward/pick-recipient");
        navigate("/inward/user-documents", { state: { kycStatus: res?.data?.status } });
      } else if (res?.data?.status === "NEWLY_VERIFIED") {
        setShowKycVerified(true);
        // navigate("/inward/pick-recipient");
        // navigate("/inward/user-documents", { state: { kycStatus: res?.data?.status } });
      } else {
        navigate("/inward/user-documents", { state: { kycStatus: res?.data?.status } });
      }
    });
  };

  useEffect(() => {
    if (selectedPurposeCode !== "") {
      setPurposeError(false);
    }
    setTransactionDetails({
      ...transactionDetailsData,
      purposeCode: confirmedPurposeValue?.code,
      transaction_purpose: confirmedPurposeValue?.value,
    });
  }, [selectedPurposeCode]);

  const handleAmountChnage = (e: { target: { value: number } }) => {
    setSelectedPurposeCode("");
    // e.target.value !== sendingAmount && setConfirmedPurpose({ value: "Select Purpose", code: "" });
  };

  const handlePromoCode = () => {
    mixpanel.track("Apply Coupon", { applyCoupon: true });
    mixpanel.track("Apply Code", { promoCode: enteredCode });
    setPromoLoading(true);
    const delayDebounce = setTimeout(() => {
      const body =
        onChangeHandlerType === "send"
          ? { user_send: sendingAmount }
          : onChangeHandlerType === "receive"
            ? { reciepient_recieve: receivingAmount }
            : { user_send: sendingAmount };

      // Send Axios request here
      getTransactionSummary({
        ...body,
        dest_currency: currency?.receiveCurrency,
        base_currency: currency?.sendingCurrency,
        hop_coins: parseInt(appliedHopCoins),
        purposeCode: confirmedPurposeValue.code,
        transaction_purpose: confirmedPurposeValue.value,
        isEducation: isLoanSanctionLetter && confirmedPurposeValue.code === "S0305" ? true : false,
        userId: currentUser.id,
        vendor_key: vendorKey,
        promo_code: enteredCode,
        isInward: isInward ? true : false,
      })
        .then((response: any) => {
          setLoading(false);
          setError("");
          setEsignedStatus({
            ...esignedStatus,
            signed: response?.data?.signed,
            signedLater: response?.data?.signedLatter,
          });
          if (
            response?.data?.promoCodeDetails?.message === "" ||
            response?.data?.promoCodeDetails?.message === null ||
            response?.data?.promoCodeDetails?.message === "Promo code applied successfully.."
          ) {
            setSelectedOfferCode(response?.data?.promoCodeDetails?.promoCode);
            setPromoCodeApplied(true);
            enteredCode === "" ? setPromoCodeApplied(false) : setPromoCodeApplied(true);
            // setIsModalOpen("");
          }
          return (
            setTransactionDetails({
              ...response.data,
              promoCodeDetails: response?.data?.promoCodeDetails,
              isEducation:
                isLoanSanctionLetter && confirmedPurposeValue.code === "S0305" ? true : false,
            }),
            setReceivingAmount(response?.data?.reciepient_recieve),
            mixpanel.track("Currency Selected", {
              "Sending Currency": currency?.sendingCurrency,
              "Receiving Currency": currency?.receiveCurrency,
            }),
            mixpanel.track("Currency Selected", {
              "Sending Currency": currency?.sendingCurrency,
              "Receiving Currency": currency?.receiveCurrency,
            }),
            mixpanel.track("Purpose Of Payment", {
              "Selected Purpose": currency?.sendingCurrency,
              "Purpose Code": confirmedPurposeValue.code,
              "Transaction Purpose": confirmedPurposeValue.value,
            }),
            mixpanel.track("Offers and Benefits", {
              "Selected Offer Code": selectedOfferCode,
            }),
            setSendingAmount(response?.data?.user_send),
            setOnChangeHandleType("send"),
            response?.data?.tcs > 0
              ? setIsTcsPresent(true)
              : response?.data?.tcs === 0
                ? (setIsTcsPresent(false), setIsLoanSanctionLetter(false))
                : setIsTcsPresent(false)
          );
        })
        .catch((error) => {
          setLoading(false);
          setError(error?.response?.data?.error?.type);
        })
        .finally(() => {
          setPromoLoading(false);
        });
    }, 1000);

    return () => clearTimeout(delayDebounce);
  };

  const checkLoanSanctionModal = () => {
    if (isTcsPresent && confirmedPurposeValue.code === "S0305") {
      setIsModalOpen("showLoanConfirmation");
    } else {
      setIsModalOpen("");
    }
  };

  useMemo(() => {
    confirmedPurposeValue?.code != undefined && checkLoanSanctionModal();
  }, [isTcsPresent]);

  React.useEffect(() => {
    // wait(1000);
    setTransactionDetails({
      ...transactionDetailsData,
      promoCodeDetails: {
        message: "",
        promoCode: "",
        promoCodeApplied: "",
        promoCodeCharge: "",
        promoCodeDiscount: "",
      },
    }),
      setSummaryLoading(true);
    const delayDebounce = setTimeout(() => {
      const body =
        onChangeHandlerType === "send"
          ? { user_send: sendingAmount }
          : onChangeHandlerType === "receive"
            ? { reciepient_recieve: receivingAmount }
            : { user_send: sendingAmount };

      // Send Axios request here
      getTransactionSummary({
        ...body,
        dest_currency: currency?.receiveCurrency,
        base_currency: currency?.sendingCurrency,
        hop_coins: parseInt(appliedHopCoins),
        purposeCode: confirmedPurposeValue?.code,
        transaction_purpose: confirmedPurposeValue.value,
        isEducation: isLoanSanctionLetter && confirmedPurposeValue?.code === "S0305" ? true : false,
        userId: currentUser.id,
        vendor_key: vendorKey,
        promo_code: selectedOfferCode,
        isInward: isInward ? true : false,
      })
        .then((response: any) => {
          // setLoading(false);
          setError("");
          setEsignedStatus({
            ...esignedStatus,
            signed: response?.data?.signed,
            signedLater: response?.data?.signedLatter,
          });
          return (
            setTransactionDetails({
              ...response.data,
              promoCodeDetails: response?.data?.promoCodeDetails,
              isEducation:
                isLoanSanctionLetter && confirmedPurposeValue.code === "S0305" ? true : false,
            }),
            setReceivingAmount(response?.data?.reciepient_recieve),
            setSendingAmount(response?.data?.user_send),
            setOnChangeHandleType("send"),
            response?.data?.tcs > 0
              ? setIsTcsPresent(true)
              : response?.data?.tcs === 0
                ? (setIsTcsPresent(false), setIsLoanSanctionLetter(false))
                : setIsTcsPresent(false)
          );
        })
        .catch((error) => {
          setError(error?.response?.data?.error?.type);
        })
        .finally(() => {
          setSummaryLoading(false);
        });
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [
    sendingAmount,
    receivingAmount,
    currency?.receiveCurrency,
    currency?.sendingCurrency,
    appliedHopCoins,
    isLoanSanctionLetter,
    selectedPurposeCode,
    confirmedPurposeValue?.value,
    noLoanConfirmationLetter,
    // promoCodeDiscount,
  ]);

  React.useEffect(() => {
    const countryData =
      isModalOpen === "sendCurrency" ? sendCurrencyCountryData : receivingCountryData;
    let tempdata = [...countryData];
    if (searchInput) {
      tempdata = tempdata.filter((data) =>
        data.countryName.toLowerCase().includes(searchInput?.toLowerCase()),
      );
    }
    setSendCurrencyModalData(tempdata);
  }, [searchInput]);

  React.useEffect(() => {
    let tempdata = [...offersModalData];
    if (offerSearchInput) {
      tempdata = tempdata.filter((data) =>
        data.offer.toLowerCase().includes(offerSearchInput?.toLowerCase()),
      );
    }
    setOfferModalData(tempdata);
  }, [offerSearchInput]);

  React.useEffect(() => {
    currency?.sendingCurrency !== "INR"
      ? (setCurrency((prev) => ({ ...prev, receiveCurrency: "INR" })), setIsInbound(true))
      : setIsInbound(false);
  }, [currency?.sendingCurrency]);

  React.useEffect(() => {
    isModalOpen === "sendCurrency"
      ? setSendCurrencyModalData(sendCurrencyCountryData)
      : setSendCurrencyModalData(receivingCountryData);
  }, [isModalOpen]);

  React.useEffect(() => {
    if (enteredCode === "") {
      handlePromoCode();
    }
  }, [enteredCode]);  

  const handleNewlyVerifiedKyc = () => {
    const body = {};
    updateNewKycStatus(body).then(() => {
      setShowKycVerified(false);
      navigate("/inward/pick-recipient");
    });
  };

  return (
    <motion.div
      className="flex flex-col justify-between flex-1 w-full lg:mt-6"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {loading ? (
        <div className="flex items-center justify-center w-full lg:mt-[20%]">
          <div className="h-[250px] w-[250px]">
            <RemitLoader />
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-between flex-1 w-full lg:mt-6">
          <DocumentUpload isKycModalOpen={isKycModalOpen} setIsKycModalOpen={setIsKycModalOpen} />
          {showKycUnderReviewModal && (
            <UnderReview
              showKycUnderReviewModal={showKycUnderReviewModal}
              setShowKycUnderReviewModal={setShowKycUnderReviewModal}
            />
          )}
          {isInward && (
            <InwardGBPModal
              showInwardGBPModal={showInwardGBPModal}
              setShowInwardGBPModal={setShowInwardGBPModal}
            />
          )}
          <KycApproved
            showKycVerified={showKycVerified}
            setShowKycVerified={setShowKycVerified}
            handleNewlyVerifiedKyc={handleNewlyVerifiedKyc}
          />
          <GoBackandStepperSection
            setIsModalOpen={setIsModalOpen}
            stepperSteps={isKycCompleted ? stepperSteps : kycStepperSteps}
          />
          <div
            className={clsx(
              "flex flex-col md:flex-row  md:mx-0 justify-between md:px-0 md:justify-around",
            )}
            id="purpose"
          >
            <div className={clsx("flex flex-col md:w-3/4 2xl:w-auto md:justify-start")}>
              <SendReceiveMoney
                sendingCurrency={currency?.sendingCurrency}
                receiveCurrency={currency?.receiveCurrency}
                sendingAmount={sendingAmount}
                receivingAmount={receivingAmount}
                setIsModalOpen={setIsModalOpen}
                setSendingAmount={setSendingAmount}
                setReceivingAmount={setReceivingAmount}
                setOnChangeHandleType={setOnChangeHandleType}
                handleAmountChnage={handleAmountChnage}
                showInwardGBPModal={showInwardGBPModal}
                setShowInwardGBPModal={setShowInwardGBPModal}
                error={error}
                setSendAmount={setSendAmount}
                sendAmount={sendAmount}
              />
              <SelectPurpose
                confirmedPurpose={confirmedPurposeValue}
                setConfirmedPurpose={setConfirmedPurpose}
                setIsModalOpen={setIsModalOpen}
                isInward={isInward}
                setIsInward={setIsInward}
                purposeError={purposeError}
                isTcsPresent={isTcsPresent}
                purposeErrorInward={purposeErrorInward}
              />
              {purposeError || purposeErrorInward ? (
                <span className="text-[14px] text-red-500 font-bold lg:hidden mb-[50px] mt-[5px]">
                  Please select a purpose code.
                </span>
              ) : null}
              <div></div>
              <ApplyPromoCode
                selectedOfferCode={selectedOfferCode}
                setIsModalOpen={setIsModalOpen}
                confirmedPurposeValue={confirmedPurposeValue}
              />

              {
                confirmedPurposeValue?.value === purposeCodeList?.[2]?.value || confirmedPurposeValue?.value === purposeCodeList?.[3]?.value ?
                  <span className="text-[14px] text-red-500 font-bold mb-[50px] ml-[10%]">
                    Coupon code cannot be applied as there are no convenience fees
                  </span> : null
              }

              {/* <CouponBanner
                heading={isInward ? "Lowest rates guaranteed" : "Limited time offer"}
                details={
                  isInward ? (
                    <>
                      <span className="text-sm font-bold">Limited time offer </span> - Now Enjoy
                      Zero convenience fees
                    </>
                  ) : (
                    <span>Enjoy 100% off on convenience fees with code</span>
                  )
                }
                code={isInward ? "" : "SUPERHOP"}
              /> */}
              {/* <DeliveryCard
              deliveryOptions={deliveryOptions}
              setSelectedDeliveryOption={setSelectedDeliveryOption}
              selectedDeliveryOption={selectedDeliveryOption}
            /> */}
            </div>
            <div>
              <PaymentDetailsCard
                removePromocode={removePromoCode}
                onClickHandler={isInward ? handleInwardClickHandler : payTotalAmount}
                confirmedPurposeValue={confirmedPurposeValue}
                receiveCurrency={currency?.receiveCurrency}
                sendingCurrency={currency?.sendingCurrency}
                sendingAmount={sendingAmount}
                selectedDeliveryOption={selectedDeliveryOption}
                selectedPromoCode={selectedOfferCode}
                promoCodeDiscount={promoCodeDiscount}
                setAppliedHopCoins={setAppliedHopCoins}
                isInward={isInward}
                inwardClickHandler={handleInwardClickHandler}
                purposeErrorInward={purposeErrorInward}
                error={error}
                loading={summaryLoading}
              />
            </div>
          </div>
          <Modals
            isModalOpen={isModalOpen}
            sendingCurrencyModalHeader={sendingCurrencyModalHeader}
            receiveCurrencyModalHeader={receiveCurrencyModalHeader}
            setIsModalOpen={setIsModalOpen}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
            sendCurrencyModalData={sendCurrencyModalData}
            sendingCurrency={currency?.sendingCurrency}
            setCurrency={setCurrency}
            selectPurposeHeader={selectPurposeHeader}
            purposeCodeList={isInward ? purposeCodeListInward : purposeCodeList}
            setSelectedPurposeCode={setSelectedPurposeCode}
            selectedPurposeCode={selectedPurposeCode}
            selectedInwardPurposeCode={selectedInwardPurposeCode}
            setSelectedInwardPurposeCode={setSelectedInwardPurposeCode}
            kycStatus={kycStatus}
            setKycStatus={setKycStatus}
            setConfirmedPurpose={setConfirmedPurpose}
            offerHeader={offerHeader}
            offerSearchInput={offerSearchInput}
            setOfferSearchInput={setOfferSearchInput}
            offerData={offerData}
            selectedOfferCode={selectedOfferCode}
            setSelectedOfferCode={setSelectedOfferCode}
            TransactioncancellationReasons={TransactioncancellationReasons}
            setCancellationReasons={setCancellationReasons}
            cancellationReasons={cancellationReasons}
            setOtherReason={setOtherReason}
            setPromoCodeApplied={setPromoCodeApplied}
            otherReason={otherReason}
            isTcsPresent={isTcsPresent}
            setIsLoanSanctionLetter={setIsLoanSanctionLetter}
            activeCoins={activeCoins}
            useCoins={useCoins}
            setUseCoins={setUseCoins}
            usable={usable}
            setUsable={setUsable}
            handleCustomPromo={() => {
              // setSelectedOfferCode(enteredCode);
              handlePromoCode();
            }}
            promoCodeError={promoCodeError}
            hopCoins={appliedHopCoins}
            setHopCoins={setAppliedHopCoins}
            setPromoCodeError={setPromoCodeError}
            enteredCode={enteredCode}
            setEnteredCode={setEnteredCode}
            promoCodeApplied={promoCodeApplied}
            isInward={isInward}
            setIsInward={setIsInward}
            setNoLoanConfirmationLetter={setNoLoanConfirmationLetter}
            loading={promoLoading}
          />
        </div>
      )}
      <div className="w-full">
        <p className="w-3/4 md:pl-[4%] pl-0">
          *These rates are indicative and include moneyHOP and processing partner’s margins.
        </p>
      </div>
    </motion.div>
  );
};

export default SendMoney;
