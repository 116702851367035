import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import PaymentDetailsCard from "./PaymentDetailsCard";
// import GoBackandStepperSection from "./GoBackandStepper";
// import { getCurrentIP, stepperSteps, TransactioncancellationReasons } from "../../utils";
// import PaymentOverview from "./PaymentOverview";
// import { useRecoilState, useRecoilValue } from "recoil";
// import { recipientDetails, transactionDetails, transactionLeadIDS, userDetails } from "../../store";
// import { initializeTransaction } from "../../services/reviewPage";
// import { UserTransactionDetails } from "../../store/store";
// import Modal from "../../components/ui/Modal";
// import Checkbox from "../../components/ui/Checkbox";
// import Button from "../../components/ui/Button";
// import { Axios } from "../../services/axios";
// import TermsModal from "../auth/SignUp/StepOne/TermsModal";
import download from "downloadjs";
import { motion } from "framer-motion";
// import { RemitLoader } from "../../components/ui/Loader";
import mixpanel from "mixpanel-browser";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserTransactionDetails } from "../../../store/store";
import {
  recipientDetails,
  transactionDetails,
  transactionLeadIDS,
  userDetails,
} from "../../../store";
import { initializeTransaction } from "../../../services/reviewPage";
import { getCurrentIP, stepperSteps, TransactioncancellationReasons } from "../../../utils";
import { Axios } from "../../../services/axios";
import GoBackandStepperSection from "../../reviewPage/GoBackandStepper";
import PaymentDetailsCard from "../../reviewPage/PaymentDetailsCard";
import Modal from "../../../components/ui/Modal";
import Checkbox from "../../../components/ui/Checkbox";
import Button from "../../../components/ui/Button";
import TermsModal from "../../auth/SignUp/TermsModal";
import InwardPaymentOverview from "./PaymentOverview";

const ReviewPageInWard = () => {
  const apiUrl = process.env.REACT_APP_SERVER_ID;
  const vendorKey = process.env.REACT_APP_VENDOR_KEY;
  const navigate = useNavigate();
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const transactionData: UserTransactionDetails = useRecoilValue(transactionDetails);
  const recipientData = useRecoilValue(recipientDetails);
  const userData = useRecoilValue(userDetails);
  const recipientId = sessionStorage.getItem("recipient_id");
  const [cancellationReasons, setCancellationReasons] = React.useState<string>("");
  const [otherReason, setOtherReason] = React.useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<string>("");
  const [thisRecipient, setThisRecipient] = useState<any>();
  const setTransactionData = useRecoilState(transactionDetails)[1];
  const [isTermsModalOpen, setIsTermsModalOpen] = React.useState<boolean>(false);
  const transactionLeadIDSArray: any = useRecoilValue(transactionLeadIDS);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [currentIP, setCurrentIP] = React.useState("");
  const [isAdvancePay, setIsAdvancePay] = useState(false);
  const [a2Loading, setA2Loading] = useState(false);
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host:process.env.REACT_APP_MIXPANEL_API,
  });

  // const transactionLeadIDSArray: any = useRecoilValue(transactionLeadIDS);

  const isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;

  const payAmount = (event: any) => {
    mixpanel.track(`Review Page - Pay Amount`);
    setLoading(true);
    event.preventDefault();
    const body = {
      advance_pay: transactionData.advance_pay,
      base_currency: transactionData.base_currency,
      cgst: transactionData.cgst,
      conversion_amount: transactionData.conversion_amount,
      conversion_rate: transactionData.conversion_rate,
      dest_currency: transactionData.dest_currency,
      hop_coins: transactionData.hop_coins,
      hop_fees: transactionData.hop_fees,
      id_recipient: recipientId,
      igst_fee: transactionData.igst_fee,
      isA2FormConsent: true,
      isEducation: transactionData?.isEducation,
      isAdvancePay: isAdvancePay ? true : false,
      isFullPay: isAdvancePay ? false : true,
      isInward: true,
      isMobWebTxn: isMobile ? true : false,
      isfromKarnataka: false,
      mgr_initial: transactionData.mgr_initial,
      nostro_charges: transactionData.nostro_charges,
      promo_code: transactionData.promo_code,
      promo_code_discount: transactionData.promo_code_discount,
      purposeCode: transactionData.purposeCode,
      reciepient_recieve: transactionData.reciepient_recieve,
      service_tax: transactionData.service_tax,
      sgst: transactionData.sgst,
      tcs: transactionData.tcs,
      total_pay: transactionData.total_pay,
      total_tax: transactionData.total_tax,
      transaction_purpose: transactionData.transaction_purpose,
      user_send: transactionData.user_send,
      vendor_key: vendorKey,
      ipAddress: currentIP,
      transactionLeadIds: transactionLeadIDSArray,
    };
    initializeTransaction(body)
      .then((response) => {
        setLoading(false);
        setTransactionData(response?.data);
        const transactionId = response?.data?.id_transaction;
        sessionStorage.setItem("transactionId", transactionId);
        navigate("/inward/payment-options", { state: { totalPay: transactionData.total_pay } });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    const currentRecipient = recipientData.filter((data: any) => {
      return data.id == recipientId;
    });
    setThisRecipient(currentRecipient);
    getCurrentIP()
      .then((response) => {
        setCurrentIP(response.data.IPv4);
      })
      .catch(() => {
        throw "Something went wrong!";
      });
  }, []);

  const handleA2Form = () => {
    setA2Loading(true);
    setLoading(true);
    Axios.get(
      `${apiUrl}/remit/user/${currentUser.id}/generateA2Form/?city=${userData.city}&dest_curr=${
        transactionData.dest_currency
      }&pan_num=&pincode=${userData.pinCode}&purpose_code=${
        transactionData.purposeCode
      }&purpose_description=${transactionData.transaction_purpose}&reciepient_recieve=${
        transactionData.reciepient_recieve
      }&recipeint_accnt_num=${thisRecipient[0].bankAccountNumber}&recipient_bank_name=${
        thisRecipient[0].bankName
      }&recipient_first_name=${thisRecipient[0].firstName}&recipient_last_name=${
        thisRecipient[0].lastName
      }&reicpient_bank_accnt_country=${thisRecipient[0].bankAccountCountry}&state=${
        thisRecipient[0].bankAccountCountry
      }&total_pay=${transactionData.total_pay}&user_address1=${
        userData.addressLine1
      }&user_first_name=${userData.firstName}&user_last_name=${
        userData.lastName
      }&user_middle_name=${userData.middleName}&recipient_middle_name=${
        thisRecipient[0].middleName
      }&recipientId=${recipientId}&bene=${
        transactionData?.purposeCode === "S1301" || transactionData?.purposeCode === "S1302"
          ? true
          : false
      }`,
      {
        headers: {
          "Access-Control-Allow-Origin": "true",
          "Content-Type": "application/pdf",
          Authorization: `${currentUser.idToken}`,
        },
        responseType: "blob",
      },
    )
      .then((response) => {
        setA2Loading(false);
        setLoading(false);
        const content = response.headers["content-type"];
        download(response.data, "A2_Form.pdf", content);
      })
      .catch((error) => {
        setA2Loading(false);
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <motion.div
      className="flex flex-col justify-between flex-1 lg:mt-12"
      // initial={{ width: 0 }}
      // animate={{ width: "100%" }}
      // exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div>
        <GoBackandStepperSection setIsModalOpen={setIsModalOpen} stepperSteps={stepperSteps} />
      </div>
      <div className="flex flex-col lg:px-[10%] px-[3%] lg:flex-row lg:justify-between">
        {/* <div>{errorMessage}</div> */}
        <PaymentDetailsCard />
        <InwardPaymentOverview
          // errorMessage={errorMessage}
          handleClick={payAmount}
          setLoading={setLoading}
          sendCurrency={transactionData.base_currency}
          destCurrency={transactionData.dest_currency}
          handleA2Form={handleA2Form}
          setIsTermsModalOpen={setIsTermsModalOpen}
          isAdvancePay={isAdvancePay}
          setIsAdvancePay={setIsAdvancePay}
          a2Loading={a2Loading}
          loading={loading}
        />
      </div>
      <Modal
        header="Resons for your Cancellation"
        headerClass={"bg-white !my-0 py-6 px-12 font-bold text-lg"}
        modalClass="!p-0 !rounded !overflow-hidden"
        visible={isModalOpen === "cancel"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="px-5 pb-5">
          {TransactioncancellationReasons.map((data, index) => {
            return (
              <div className="space-x-2 px-7 border-b-[1px] py-6 first:pt-0" key={index}>
                <Checkbox
                  label={data}
                  value={data}
                  name={"cancellationReason"}
                  handleChange={(e) => {
                    setCancellationReasons(e.target.value);
                  }}
                />
              </div>
            );
          })}
          {cancellationReasons === "Other Reason..." && (
            <div className="flex items-center justify-center mt-2">
              <textarea
                name="otherReasonDescription"
                value={otherReason}
                rows={4}
                cols={50}
                placeholder="Write your reason here....."
                onChange={(e) => {
                  setOtherReason(e.target.value);
                }}
                className="w-full p-3 bg-white-100 focus-visible:border-0 placeholder:text-center"
              />
            </div>
          )}
          <div className="flex items-center justify-center mt-7 px-[78px]">
            <Button
              id="backsy-snbjcyu"
              bgColor={`${cancellationReasons === "" ? "bg-grey-350" : "bg-primary"}`}
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderWidth: "border-none",
                borderRadius: "rounded-[100px]",
              }}
              disabled={cancellationReasons === ""}
              className={"py-3 px-28"}
              onClickHandler={() =>
                cancellationReasons !== "" && cancellationReasons === "Other Reason..."
                  ? otherReason !== "" && navigate("/")
                  : navigate("/")
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <TermsModal isTermsModalOpen={isTermsModalOpen} setIsTermsModalOpen={setIsTermsModalOpen} />
    </motion.div>
  );
};

export default ReviewPageInWard;
