/* eslint-disable */
import React, { useEffect } from "react";
import clsx from "clsx";

import {
  DropdownIcon,
  GrowthIcon,
  HashtagIcon,
  PhoneInhand,
  // RightArrowIcon,
  TransferIcon,
} from "../../assets/Icons";
import { GAP_FROM_SIDEBAR } from "../../components/layout/ProtectedPageLayout";
import Button from "../../components/ui/Button";
import Carousel from "../../components/ui/Carousel";
import DealCard from "../../components/ui/DealCard";
import HistoryCard from "../../components/ui/HistoryCard";
import InfographicCard from "../../components/ui/InfographicCard";
import Typograpy from "../../components/ui/Typography";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "../../components/ui/Avatar";
import mixpanel from "mixpanel-browser";
import Modal from "../../components/ui/Modal";
import SendingMoney from "../userDocuments/KycModals/kycStatusModals/sendingMoney";
import { downloadInvoice, getTransactionHistory } from "../../services/history";
import { useRecoilValue } from "recoil";
import { userDetails } from "../../store";
import download from "downloadjs";
import { RemitLoader } from "../../components/ui/Loader";

const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
mixpanel.init(mixpanelToken, {
  debug: true,
  api_host:process.env.REACT_APP_MIXPANEL_API,
});

export interface IRecepientContainerProps {
  recepientName?: {
    fname: string;
    lname: string;
    id: any;
  }[];
  isInward?: any;
  setIsInward?: any;
}

const email = sessionStorage.getItem("email") || "";

const RecepientContainer: React.FunctionComponent<IRecepientContainerProps> = ({
  recepientName = [],
  // isInward,
  setIsInward,
}) => {
  const [sendingCurrency, setSendingCurrency] = React.useState("INR");
  const [receiveCurrency, setReceiveCurrency] = React.useState("USD");

  const [showInwardModal, setShowInwardModal] = React.useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    recepientName.slice(0, 3).map((el, id) => {
      window.console.log("el.id", el.id);
    });
  }, [recepientName]);

  return (
    <div className={clsx(GAP_FROM_SIDEBAR, "overflow-x-hidden")}>
      <Typograpy tagType="span" text="Your Recipients" className="text-lg font-medium" />
      <SendingMoney
        showInwardModal={showInwardModal}
        setShowInwardModal={setShowInwardModal}
        setIsInward={setIsInward}
      />
      <div className={clsx("flex mt-4 items-center", recepientName.length ? "gap-6" : "gap-8")}>
        <div className="">
          {/* <Link
            onClick={() => mixpanel.track("Add Recipient Dashboard")}
            to={"/recipient-details"}
            className=""
          >
            <Button
              id="+-text-btn"
              bgColor="bg-transparent border flex items-center justify-center opacity-50 text-[50px] w-[65px] h-[65px] md:w-[74px] mb-2.5 md:h-[74px] rounded-full border-primary text-primary"
            >
              <span className="-mt-2">+</span>
            </Button>
          </Link> */}
          <Button
            id="+-text-btn"
            onClickHandler={() => setShowInwardModal(true)}
            bgColor="bg-transparent border flex items-center justify-center opacity-50 text-[50px] w-[65px] h-[65px] md:w-[74px] mb-2.5 md:h-[74px] rounded-full border-primary text-primary"
          >
            <span className="">+</span>
          </Button>
          <Typograpy text="Add" className="text-xs text-center" tagType="div" />
        </div>
        {recepientName.length ? (
          recepientName.slice(0, 3).map((el, id) => (
            <div
              onClick={() => {
                sessionStorage.setItem("recipient_id", el.id);
                sessionStorage.setItem("recipient_name", `${el.fname}`);
                // sessionStorage.setItem("isRecipientSelected", "true");
              }}
              className="flex flex-col items-center transition-all rounded-full cursor-pointer group hover:scale-105"
              key={el.fname + id}
            >
              <div className="flex flex-col items-center transition-all rounded-full cursor-pointer hover:animate-bounce ">
                <Link to={"/send-money"}>
                  <Avatar
                    name={el.fname.charAt(0) + el?.lname.charAt(0)}
                    customClassname="!w-[65px] !h-[65px] md:!w-[74px] md:!h-[74px] mb-2.5"
                    textClassname="!text-[24px] md:text-[34px]"
                  />
                  <Typograpy
                    text={el.fname}
                    className="text-xs text-center transition-all group-hover:font-bold"
                    tagType="div"
                  />
                </Link>
              </div>
            </div>
          ))
        ) : (
          <div>
            <Typograpy
              tagType="span"
              text="No recipients added yet"
              className="text-sm opacity-50 md:text-lg"
            />
            <Typograpy
              tagType="div"
              text={`Click the “Add” button to add recipients`}
              className="opacity-50 text-base md:text-[24px]"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export interface IInfographicCardContainerProps {
  noOfTx: number;
  amountRemitted: number;
  chargesSaved: number;
}
const InfographicCardContainer: React.FunctionComponent<IInfographicCardContainerProps> = ({
  noOfTx,
  amountRemitted,
  chargesSaved,
}) => (
  <div
    className={clsx(
      "mt-7 flex justify-center md:justify-start flex-wrap xl:flex-nowrap gap-2 md:gap-5",
      GAP_FROM_SIDEBAR,
    )}
    id="infographicCard"
  >
    <InfographicCard title="No. Of Transactions done" subtitle={noOfTx} icon={<HashtagIcon />} />
    <InfographicCard
      title="Amount Remitted"
      subtitle={`₹ ${amountRemitted}`}
      icon={<TransferIcon />}
      cardClassname="grow md:grow-0"
    />
    <InfographicCard
      title="Saved in hidden charges"
      subtitle={`₹ ${chargesSaved}`}
      icon={<GrowthIcon className="w-6 h-6" />}
      cardClassname="grow md:grow-0"
    />
    {/* <InfographicCard cardClassname="bg-blue-100 flex items-center justify-center gap-6 cursor-pointer hover:bg-gray-100 hover:border transition-all">
      <div
        onClick={() => (window.location.href = "/history")}
        className="flex items-center transition-all bg-red md:gap-6"
      >
        <RightArrowIcon className="w-12 scale-75 h-7" />
        <div className="flex transition-all items-center justify-center h-full text-primary font-semibold max-w-[100px] text-[10px] lg:text-[16px]">
          See what you have done
        </div>
      </div>
    </InfographicCard> */}
  </div>
);

export interface IDealCardContainerProps {
  dealCard: {
    text: string;
    onClick: any;
    bgUrl: string;
    textClassname: string;
    buttonText: string | React.ReactNode;
    buttonClassname: string;
  }[];
}

const DealCardContainer: React.FunctionComponent<IDealCardContainerProps> = ({ dealCard }) => (
  <div className="pb-6 group mt-7 md:pl-10 bg-primary-800 md:bg-deal-card-bg">
    <Typograpy
      tagType="h6"
      text="Deals"
      className="py-3 pl-5 text-[26px] font-ssp font-medium md:pl-0 group-hover:text-[30px] transition-all"
    />
    <div className="md:hidden">
      <Carousel visibleSlides={1}>
        {dealCard.map((el: any) => (
          <DealCard wholeCardClickable fullWidth key={el.text} {...el} bgUrl={`url(${el.bgUrl})`} />
        ))}
      </Carousel>
    </div>
    <div className="flex-wrap hidden gap-11 md:flex">
      {dealCard.map((el: any) => (
        <DealCard wholeCardClickable key={el.text} {...el} bgUrl={`url(${el.bgUrl})`} />
      ))}
    </div>
  </div>
);

export interface IHistoryCardContainerProps {
  historyCardDetails?: {
    txNo: string;
    receiverFname: string;
    receiverLname: string;
    date: string;
    totalPay: number;
    baseCurrency: string;
    recepientReceive: number;
    destinationCurrency: string;
    countryOfResidence: string;
    conversionRate: number;
    status:
      | "CANCELLED"
      | "COMPLETED"
      | "IN_TRANSIT"
      | "AWAITING_PAYMENT"
      | "AWAITING_FULL_PAYMENT"
      | "INITIATE_REFUND"
      | "REFUND_COMPLETED";
    statusToBeShown:
      | "CANCELLED"
      | "COMPLETED"
      | "IN_TRANSIT"
      | "AWAITING_PAYMENT"
      | "INITIATE_REFUND"
      | "REFUND_COMPLETED"
      | "ASSIGNED_TO_PROCESSING"
      | "PAYMENT_VERIFICATION_UNDER_PROCESS"
      | "DOCUMENT_VERIFICATION_UNDER_PROCESS"
      | "REQUIRE_MORE_DOCUMENTS"
      | "AWAITING_FULL_PAYMENT";
    userFname: string;
    userLname: string;
    baseCountry: string;
    accNo: number;
    totalDeduction: number;
    paymentMode: string;
    convertedAmount: number;
  }[];
}

const HistoryCardContainer: React.FunctionComponent<IHistoryCardContainerProps> = ({
  historyCardDetails = [],
}) => {
  const [transactions, setTransactions] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filteredTransactions, setFilteredTransactions] = React.useState<any>([]);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const userData = useRecoilValue(userDetails);
  const noHistoryFound = (
    <div
      className={clsx(
        "md:bg-primary-800 flex item-center  justify-center text-[24px] text-primary",
      )}
    >
      <div className="flex flex-col justify-center text-center opacity-50 md:opacity-100 md:text-left">
        <Typograpy tagType="div" text="You are empty on your history," />
        <Typograpy tagType="div" className="" text="Start using it to" />
        <Typograpy tagType="div" className="font-bold " text="make your history" />
      </div>
      <PhoneInhand className="hidden md:block" />
    </div>
  );

  const handleFilterDataCriteria = () => {
    const filteredList = transactions?.filter((el: any) => {
      return el;
    });

    setFilteredTransactions(filteredList);
  };

  useEffect(() => {
    setLoading(true);
    getTransactionHistory(currentUser).then((res: any) => {
      setLoading(false);
      if (res.data) setTransactions(res.data);
    });
  }, []);

  useEffect(() => {
    handleFilterDataCriteria();
  }, [transactions]);

  const downloadInvoiceHandler = (id: string) => {
    downloadInvoice(currentUser, id, "RECEIPT")
      .then((response: any) => {
        const content = response.headers["content-type"];
        download(response.data, "download_" + "RECEIPT" + ".pdf", content);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const singleTx = (
    <div>
      {historyCardDetails?.length && <HistoryCard {...historyCardDetails[0]} />}
      <Typograpy
        className="opacity-50 text-singleHistoryText text-[24px] md:leading[72px] md:text-[72px] text-center mt-4 md:mt-12"
        tagType="div"
      >
        Made your first <span className="!text-primary-600">{`"History".`}</span>
      </Typograpy>
      <Typograpy
        tagType="div"
        text="Be in the marathon by doing more trasaction"
        className="text-primary-600 opacity-50 md:leading-[48px] text-lg md:text-[48px] text-center"
      />
    </div>
  );
  // const navigate = useNavigate();
  const multipleTx = (
    <div className="flex flex-col gap-3">
      {filteredTransactions?.length &&
        filteredTransactions.slice(0, 3).map((el: any, id: any) => (
          <div>
            <HistoryCard
              accNo={el?.userTransaction?.recipient?.bankAccountNumber}
              baseCurrency={el?.userTransaction?.baseCurrency}
              conversionRate={el?.userTransaction?.conversionRate}
              recepientReceive={el?.userTransaction?.recipientRecieve}
              countryOfResidence={el?.userTransaction?.recipient?.countryOfResidence}
              date={el?.userTransaction?.createdOn}
              destinationCurrency={el?.userTransaction?.destinationCurrency}
              paymentMode={el?.userTransaction?.transactionState?.paymentMode}
              status={el?.userTransaction?.status}
              statusToBeShown={
                el?.userTransaction?.status === "INITIATE_REFUND" ||
                el?.userTransaction?.status === "REFUND_COMPLETED"
                  ? el?.userTransaction?.status
                  : el?.statusToBeShown
              }
              totalDeduction={el?.userTransaction?.userSend}
              txNo={el?.userTransaction?.transactionNumber}
              baseCountry={userData?.country}
              userFname={userData?.firstName}
              userLname={userData?.lastName}
              receiverFname={el?.userTransaction?.recipient?.firstName}
              receiverLname={el?.userTransaction?.recipient?.lastName}
              totalPay={el?.userTransaction?.userSend}
              convertedAmount={el?.userTransaction?.conversionAmount}
              id={el?.userTransaction?.id}
              downloadInvoiceHandler={downloadInvoiceHandler}
              is_fullPaid={el?.userTransaction?.transactionState?.is_fullPaid}
              isTotalPay={el?.userTransaction?.isTotalPay}
              total_pay={el?.userTransaction?.totalPay}
              advancePay={el?.userTransaction?.advancePay}
              isAdvancePay={el?.userTransaction?.isAdvancePay}
              purpose={el?.userTransaction?.transactionPurpose}
              isInward={el?.userTransaction?.isInward}
              taxAndFees={
                el?.userTransaction?.transactionTaxDetails?.nostroCharges +
                el?.userTransaction?.transactionTaxDetails?.tcs +
                el?.userTransaction?.transactionTaxDetails?.serviceTax
              }
              promoCode={
                el?.userTransaction?.transactionState?.is_promoApplied
                  ? el?.userTransaction?.transactionState?.promoCode
                  : "0"
              }
              totalPayable={el?.userTransaction?.totalPay}
            />
          </div>
        ))}
    </div>
  );

  const loader = (
    <div className="flex items-center justify-center w-full lg:mt-[10%]">
      <div className="h-[250px] w-[250px]">
        <RemitLoader />
      </div>
    </div>
  );

  return (
    <div className="mt-3 bg-primary-800 pb-7 md:pb-0 md:bg-transparent">
      <div className={clsx("pt-7 md:pt-0 md:mt-7", GAP_FROM_SIDEBAR)}>
        <Typograpy
          tagType="h6"
          text="Your Recent Transactions"
          className="py-3 text-[18px] font-medium"
        />
        {historyCardDetails?.length > 1
          ? loading
            ? loader
            : multipleTx
          : historyCardDetails?.length === 1
          ? multipleTx
          : noHistoryFound}
      </div>
    </div>
  );
};

export { DealCardContainer, InfographicCardContainer, RecepientContainer, HistoryCardContainer };
