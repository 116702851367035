/* eslint-disable */
import { useEffect, useState, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { DebitCard, Neft, NetBanking, Upi, MoneyHopLogoWhite } from "../../assets/Icons";
import {
  bankDownTime,
  fetchNeftBankDetails,
  getBankNames,
  getPersonalAccountDetails,
  pennyDropAPI,
  razorPayResponse,
  requestRazor,
} from "../../services/payments";
import { useNavigate } from "react-router-dom";
import { transactionDetails, transactionId, userDetails } from "../../store";
import { UserDetails, UserTransactionDetails } from "../../store/store";
import { findNetBankingName, formatNumber, stepperSteps } from "../../utils";
import GoBackandStepperSection from "./GoBackandStepper";
import { Modals } from "./Modals";
import PaymentProps from "./payment";
import { motion } from "framer-motion";
import mixpanel from "mixpanel-browser";
import { customAlert } from "../../utils/customAlert";
import { RemitLoader } from "../../components/ui/Loader";
import { useLocation } from "react-router-dom";
// import { options } from "yargs";
// import {load} from '@cashfreepayments/cashfree-js';

declare global {
  interface Window {
    Cashfree?: any;
    Razorpay?: any;
  }
}

const validPurposes = ["S1301", "S1302", "Family Maintenance", "Personal Gift or Donation"];

const PaymentOptionsPage = (props: any) => {
  const transactionData: UserTransactionDetails = useRecoilValue(transactionDetails);
  const [, setTransactionID] = useRecoilState(transactionId);
  const userData: UserDetails = useRecoilValue(userDetails);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState<string>("");
  const [bankList, setBankList] = useState<any>([]);
  const [bankName, setBankName] = useState<string>("");
  const [acNumber, setAcNumber] = useState<string>("");
  const [cashfreeBackdrop, setCashfreeBackdrop] = useState<boolean>(false);
  const [upiBackdrop, setUpiBackdrop] = useState<boolean>(false);
  const [netBankError, setNetBankError] = useState<string>("");
  const [debitCardError, setDebitCardError] = useState<string>("");

  const [ifsc, setIfsc] = useState<string>("");
  const [pennyError, setPennyError] = useState<string>("");
  const [upiError, setUpiError] = useState<string>("");
  const [upiId, setUpiId] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [ifscNumber, setIfscNumber] = useState<string>("");
  const [neftBankDetails, setNeftBankDetails] = useState({} as PaymentProps.neftBankDetails);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [razorpayOrderId, setRazorpayOrderId] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRazorpay, setIsRazorpay] = useState<boolean>(true);
  const [pennyLoading, setPennyLoading] = useState<boolean>(false);
  const [upiLoading, setUpiLoading] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [onlyNeft, setOnlyNeft] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [bankId, SetBankID] = useState<any>("");
  const [disablePay, setDisablePay] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const findBankId = (value: any) => {
    const Id = bankList.find((bank: any) => bank?.bankCode === value);
    if (Id) {
      SetBankID(Id?.bankId);
    }
  };

  useEffect(() => {
    upiId === "" || accountNumber === "" || ifscNumber === ""
      ? setDisablePay(true)
      : setDisablePay(false);
  }, [upiId, accountNumber, ifscNumber]);

  useEffect(() => {
    findBankId(bankName);
  }, [bankName]);

  const getBankList = () => {
    getBankNames(currentUser)
      .then((response: any) => {
        setBankList(response?.data?.banks);
      })
      .catch((error) => {
        setNetBankError(error?.response?.data?.error?.type);
      });
  };

  useEffect(() => {
    getBankList();
    getBankDetails();
  }, []);

  useMemo(() => {
    location.state?.purpose && setOnlyNeft(validPurposes.includes(location.state?.purpose));
  }, [location]);

  const boxStyle =
    "flex sm:flex-row md:flex-col items-center space-y-5 mt-5 md:mt-0 justify-center md:justify-center w-[400px] md:w-[200px] bg-white h-[130px] md:h-[200px] rounded-lg hover:bg-gray-200 cursor-pointer hover:scale-105 transition-all";
  const disabledBoxStyle =
    "flex sm:flex-row md:flex-col items-center space-y-5 mt-5 md:mt-0 justify-center md:justify-center w-[400px] md:w-[200px] bg-white h-[130px] md:h-[200px] rounded-lg hover:bg-gray-200 cursor-not-allowed hover:cursor-not-allowed hover:scale-105 transition-all";
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });
  const [netbankList, setNetbankList] = useState<any>();
  const localTransactionId: any = sessionStorage.getItem("transactionId");
  const queryParameters = new URLSearchParams(window.location.search);
  const cashfreeOrderId = queryParameters.get("order_id");
  const [debitCardDetails, setDebitCardDetails] = useState<any>({
    number: "",
    name: "",
    expiry_month: "",
    expiry_year: "",
    cvv: "",
  });
  const [downNetBanks, setDownNetBanks] = useState<any>([]);
  const [downVpas, setDownVpas] = useState<any>([]);
  const emailId = sessionStorage.getItem("email") || "";
  const [bankDetails, setBankDetails] = useState<any>([]);

  const getBankDetails = () => {
    setLoading(true);
    getPersonalAccountDetails(currentUser)
      .then((res) => {
        if (res?.data?.AccountDetails?.length > 0) {
          setBankDetails(res?.data?.AccountDetails);
          setLoading(false);
        } else {
          setBankDetails([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        window.console.error("Error", err);
        setLoading(false);
      });
  };

  const handleAddNewBank = () => {
    handleBankDownTime();
    setIsModalOpen("netBanking");
    mixpanel.track("Payment mode_Net Banking", {
      "Bank ID": bankId,
    });
  };

  const handleBankDownTime = () => {
    bankDownTime().then((res: any) => {
      setDownNetBanks(res?.data?.banks);
      setDownVpas(res?.data?.vpas);
    });
  };

  const openRazorpayWindow = (data: any) => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("id", "razorpayFrame");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/razorpay.js";
    script.async = true;
    script.onload = () => {
      const nbInstance = new window.Razorpay({
        // key: "rzp_live_68nZN0xjIPxJjI",
        key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
        image: "https://i.imgur.com/n5tjHFD.png",
      });
      const instance = new window.Razorpay({
        key: `${process.env.REACT_APP_RAZORPAY_KEY_NETBANKING}`,
        // key: "rzp_live_XxICSungzwAMpM",
        image: "https://i.imgur.com/n5tjHFD.png",
      });
      instance.once("ready", function (response: any) {
        setNetbankList(response?.methods?.netbanking);
      });
      const dataArray = [];
      for (let code in netbankList) {
        dataArray.push({ code: code, name: netbankList[code] });
      }
      //netbanking initialisation
      if (data.method === "netbanking") {
        nbInstance.createPayment(data);
        nbInstance.on("payment.success", (resp: any) => {
          const data = {
            razorpay_order_id: resp.razorpay_order_id ?? "",
            razorpay_payment_id: resp.razorpay_payment_id ?? "",
            razorpay_signature: resp.razorpay_signature ?? "",
            isRazorPay: true,
          };
          razorPayResponse(data)
            .then((response: any) => {
              if (response.status === 200) {
                sessionStorage.setItem("matchingUrl", response.data.redirectUrl);
                window.location.href = "/confirmation";
                setTransactionID(transactionData?.transactionNumber);
                sessionStorage.setItem("trxnNum", transactionData?.transactionNumber);
                mixpanel.track("Payment Successful");
              }
            })
            .catch((err: any) => {
              console.error(err, "razorPayResponseError");
            });
        });

        nbInstance.on("payment.error", (resp: any) => {
          alert(resp.error.description);
          setIsModalOpen("");
        });
      } else {
        instance.createPayment(data);
        instance.on("payment.success", (resp: any) => {
          const data = {
            razorpay_order_id: resp.razorpay_order_id ?? "",
            razorpay_payment_id: resp.razorpay_payment_id ?? "",
            razorpay_signature: resp.razorpay_signature ?? "",
            isRazorPay: true,
          };
          razorPayResponse(data)
            .then((response: any) => {
              if (response.status === 200) {
                sessionStorage.setItem("matchingUrl", response.data.redirectUrl);
                window.location.href = "/confirmation";
                mixpanel.track("Payment Successful");
                setTransactionID(transactionData?.transactionNumber);
                sessionStorage.setItem("trxnNum", transactionData?.transactionNumber);
              }
            })
            .catch((err: any) => {
              console.error(err, "razorPayResponseError");
            });
        });

        instance.on("payment.error", (resp: any) => {
          alert(resp.error.description);
          setIsModalOpen("");
        });
      }
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (cashfreeOrderId) {
      const body: any = {
        razorpay_order_id: cashfreeOrderId,
        razorpay_payment_id: "",
        isRazorPay: false,
        razorpay_signature: "",
      };
      razorPayResponse(body).then((response: any) => {
        if (response.status === 200) {
          sessionStorage.setItem("matchingUrl", response.data.redirectUrl);
          window.location.href = "/confirmation";
          mixpanel.track("Payment Successful");
        }
      });
    }
  });

  useEffect(() => {
    fetchNeftBankDetails()
      .then((response) => setNeftBankDetails(response?.data))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDebitCard = () => {
    mixpanel.track("Debit Card Clicked");
    const body = {
      accNum: "",
      ifsc: "",
      isAgree: true,
      paymentMode: "DEBIT_CARD",
      transactionID: transactionData?.id_transaction
        ? transactionData?.id_transaction
        : localTransactionId,
      userId: 0,
      userKey: currentUser.id,
    };
    setIsLoading(true);
    requestRazor(body)
      .then((response) => {
        if (response?.data?.openRazorpay === false) {
          setIsModalOpen("");
          setDebitCardError("");
          setRazorpayOrderId(response?.data?.orderID);
          const dropinConfig = {
            components: ["card", "order-details"],
            onSuccess: function (data: any) {
              const body = {
                razorpay_order_id: data.order.orderId ?? "",
                razorpay_payment_id: data.transaction.transactionId ?? "",
                isRazorPay: true,
                razorpay_signature: "",
              };
              razorPayResponse(body)
                .then((response: any) => {
                  if (response.status === 200) {
                    sessionStorage.setItem("matchingUrl", response.data.redirectUrl);
                    window.location.href = "/confirmation";
                    setTransactionID(transactionData?.transactionNumber);
                    sessionStorage.setItem("trxnNum", transactionData?.transactionNumber);
                    setDebitCardError("");
                    mixpanel.track("Payment Successful");
                  }
                })
                .catch((err: any) => {
                  /* eslint-disable-next-line */
                  setDebitCardError(err?.response?.data?.type);
                  console.error(err, "razorPayResponseError");
                })
                .finally(() => {
                  setIsLoading(false);
                });
            },
            onFailure: function (data: any) {
              /* eslint-disable-next-line */
            },
            style: {
              backgroundColor: "#ffffff",
              color: "#11385b",
              fontFamily: "Lato",
              fontSize: "14px",
              errorColor: "#ff0000",
              theme: "light",
            },
          };
          const cashfree: any = new window.Cashfree(response?.data?.order);
          cashfree.drop(document.getElementById("payment-form"), dropinConfig);
        } else {
          const data = {
            order_id: response?.data?.orderID,
            amount: response?.data?.amount,
            currency: "INR",
            email: userData?.email,
            contact: userData?.phoneNumber,
            method: "card",
            "card[name]": debitCardDetails?.name,
            "card[number]": debitCardDetails?.number,
            "card[cvv]": debitCardDetails?.cvv,
            "card[expiry_month]": debitCardDetails?.expiry_month,
            "card[expiry_year]": debitCardDetails?.expiry_year,
          };
          openRazorpayWindow(data);
        }
      })
      .catch((error) => {
        setDebitCardError(error?.response?.data[0]?.type);
        setIsLoading(false);
      });
  };

  const handleNetBanking = () => {
    setPennyLoading(true);
    const body = {
      cusAcc: acNumber,
      ifscCode: ifsc,
      isVpa: false,
      transactionID: transactionData?.id_transaction
        ? transactionData?.id_transaction
        : localTransactionId,
      userId: 0,
      userKey: currentUser.id,
      vpa: "",
      saveAccount: true,
      bankId: bankId || "",
    };
    pennyDropAPI(body)
      .then((response) => {
        const body = {
          accNum: acNumber,
          ifsc: ifsc,
          paymentMode: "NET_BANKING",
          transactionID: transactionData?.id_transaction
            ? transactionData?.id_transaction
            : localTransactionId,
          userId: 0,
          bankId: bankId || "",
          userKey: currentUser.id,
        };
        requestRazor(body)
          .then((response) => {
            if (response?.data?.openRazorpay === false) {
              setIsRazorpay(false);
              setIsModalOpen("");

              // const cashfree: any = new window.Cashfree(response?.data?.order);
              // cashfree.drop(document.getElementById("payment-form"), dropinConfig);
              // cashfree.redirect();
              let cashfree = window.Cashfree({
                mode: `${process.env.REACT_APP_CASHFREE_ENV}`, //or production
              });
              const bank = findNetBankingName(bankName);
              let netbanking = cashfree.create("netbanking", {
                values: {
                  netbankingBankName: bank,
                },
              });
              netbanking.on("loaderror", function (data: any) {});
              netbanking.mount("#payment-form");
              let paymentOptions = {
                paymentMethod: netbanking,
                paymentSessionId: response?.data?.order,
                // returnUrl: "https://test.cashfree.com/pgappsdemos/v3success.php?myorder={order_id}",
              };
              netbanking.on("ready", function (d: any) {
                cashfree
                  .pay(paymentOptions)
                  .then(function (data: any) {
                    setCashfreeBackdrop(false);
                  })
                  .catch((error: any) => customAlert(error?.message));
              });
              setPennyError("");
              setCashfreeBackdrop(true);
            } else {
              var data = {
                key_id: response?.data?.key,
                amount: response?.data?.amount,
                order_id: response?.data?.orderID,
                currency: "INR",
                email: userData?.email ?? "user@email.com",
                // email: "lmfao@lmao.com",
                contact: userData?.phoneNumber,
                method: "netbanking",
                bank: bankName,
              };
              openRazorpayWindow(data);
            }
          })
          .catch((err) => {
            err?.response?.data[0]?.type && setPennyError(err?.response?.data[0]?.type);
            err?.response?.data?.error?.type && setPennyError(err?.response?.data?.error?.type);
          })
          .finally(() => {
            setPennyLoading(false);
          });
      })
      .catch((error) => {
        setPennyLoading(false);
        setPennyError(error?.response?.data?.error?.type);
        setTimeout(() => {
          setPennyError("");
        }, 5000);
      });
  };

  const initCash = (data: any) => {
    setLoading(false);
    let paymentBtn: any = document.getElementById("pay-collect");
    paymentBtn.disabled = true;
    let paymentMessage: any = document.getElementById("paymentMessage");

    let cashfree = window.Cashfree({ mode: `${process.env.REACT_APP_CASHFREE_ENV}` });
    let opt = {
      values: {
        upiId: upiId,
      },
    };
    let component = cashfree.create("upiCollect", opt);
    // component.mount("#vpainput");
    component.mount(document.getElementById("vpainput"));

    function toggleBtn() {
      if (component.isComplete()) {
        paymentBtn.disabled = false;
      } else {
        paymentBtn.disabled = true;
      }
    }
    component.on("change", function (data: any) {
      toggleBtn();
    });

    let paymentOptions = {
      paymentMethod: component,
      paymentSessionId: data?.order,
      // returnUrl: "https://test.cashfree.com/pgappsdemos/v3success.php?myorder={order_id}",
    };

    paymentBtn.addEventListener("click", function () {
      paymentBtn.disabled = true;
      cashfree.pay(paymentOptions).then(function (data: any) {
        setUpiBackdrop(false);
        if (data.error) {
          customAlert(data.error.message);
        }
      });
    });
  };

  const handleUpi = () => {
    setUpiLoading(true);
    const body = {
      cusAcc: acNumber,
      ifscCode: ifsc,
      isVpa: true,
      transactionID: transactionData?.id_transaction
        ? transactionData?.id_transaction
        : localTransactionId,
      userKey: currentUser.id,
      vpa: upiId,
    };
    setLoading(true);
    pennyDropAPI(body)
      .then(() => {
        setUpiLoading(false);
        const body = {
          accNum: accountNumber,
          ifsc: ifscNumber,
          paymentMode: "UPI",
          transactionID: transactionData?.id_transaction
            ? transactionData?.id_transaction
            : localTransactionId,
          userId: 0,
          userKey: currentUser.id,
          upiId: upiId,
        };
        requestRazor(body)
          .then((response) => {
            setIsModalOpen("");
            setUpiBackdrop(true);
            setLoading(false);
            initCash(response?.data);
          })
          .catch((err) => {
            setUpiError(err?.response?.data?.error?.type);
            setLoading(false);
          });
      })
      .catch((error) => {
        setUpiLoading(false);
        setUpiError(error?.response?.data?.error?.type);
        setTimeout(() => {
          setUpiError("");
        }, 5000);
      });
  };

  const handleSelectBank = () => {
    navigate("/payment-method", { state: { transactionData } });
    mixpanel.track("Payment mode_Net Banking Bank_select");
  };

  return (
    <motion.div
      className="bg-primary-800"
      // initial={{ width: 0 }}
      // animate={{ width: "100%" }}
      // exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      {loader ? (
        <div className="flex items-center justify-center mt-[20%] w-[120px] ml-[150px] lg:w-[330px] lg:ml-[40%]">
          <RemitLoader />
        </div>
      ) : (
        <div>
          <div className="flex flex-col">
            <div id="stepper" className="md:mt-6">
              <GoBackandStepperSection setIsModalOpen={undefined} stepperSteps={stepperSteps} />
            </div>
            <div id="youPay header" className="flex flex-col items-center">
              <span className="text-[20px] md:text-[28px] font-semibold space-x-4">
                Payable Amount :
                <span className="ml-2 mr-1">
                  {transactionData?.isAdvancePay
                    ? formatNumber(transactionData?.advance_pay)
                    : formatNumber(transactionData?.total_pay)}
                </span>
                {transactionData?.base_currency}
              </span>
              <span className="text-[14px] md:text-[20px]">Select the mode of payment.</span>
            </div>
            <div
              id="boxes"
              className="flex flex-col items-center justify-center mt-5 md:flex-row md:mt-28 md:space-x-20"
            >
              <div
                id="neft"
                onClick={() => {
                  setIsModalOpen("neft");
                  mixpanel.track("Payment mode_NEFT");
                  // navigate("/payment-method");
                }}
                className={boxStyle}
              >
                <Neft />
                <span className="ml-10 font-bold text-gray-600 md:ml-0">NEFT/RTGS</span>
              </div>
              {emailId.includes("@moneyhop.co") && (
                <div
                  id="debit"
                  onClick={() => {
                    setIsModalOpen("debitCard");
                    mixpanel.track("Payment mode_Debit card");
                  }}
                  className={onlyNeft ? "hidden" : boxStyle}
                >
                  <DebitCard />
                  <span className="ml-10 font-bold text-gray-600 md:ml-0">Debit Card</span>
                </div>
              )}
              <div
                id="netbank"
                onClick={() => {
                  // handleBankDownTime();
                  // setIsModalOpen("netBanking");
                  // mixpanel.track("Payment mode_Net Banking");
                  bankDetails?.length > 0 ? handleSelectBank() : handleAddNewBank();
                }}
                className={onlyNeft ? "hidden" : boxStyle}
              >
                <NetBanking />
                <span className="ml-10 font-bold text-gray-600 md:ml-0">Net Banking</span>
              </div>
              <div
                id="upi"
                onClick={() => {
                  handleBankDownTime();
                  setIsModalOpen("upi");
                  mixpanel.track("Payment mode_UPI");
                }}
                className={onlyNeft ? "hidden" : boxStyle}
              >
                <div className="-ml-10 md:ml-0">
                  <Upi />
                </div>
                <span className="ml-16 font-bold text-gray-600 md:ml-0">UPI</span>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "fixed",
              left: "0px",
              top: "0px",
              height: "100vh",
              width: "100vw",
              backgroundColor: "rgba(0,0,0,0.5)",
              display: cashfreeBackdrop ? "block" : "none",
            }}
          >
            <div className="flex items-center justify-center w-full h-full">
              <div id="payment-form" className="w-[500px] h-[500px] z-50"></div>
            </div>
          </div>

          <div
            className=" justify-center items-center"
            style={{
              position: "fixed",
              left: "0px",
              top: "0px",
              height: "100%",
              width: "100%",
              display: upiBackdrop ? "block" : "none",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100%",
              }}
            >
              <div className="bg-white flex justify-center items-center rounded-lg shadow-lg p-8 w-1/4">
                <div className="mb-2">
                  <h4 className="text-lg font-bold"></h4>
                </div>
                <div className="w-full flex flex-col justify-center items-center">
                  <div className="flex rounded-lg items-center w-fit justify-center py-2 bg-primary-200 lg:space-x-20">
                    <MoneyHopLogoWhite />
                  </div>
                  <div className="flex items-center w-full mb-4 justify-center mt-5 py-2">
                    <div id="vpainput" className="w-0 h-0"></div>
                    <span className="text-center text-lg">
                      Press continue to receive payment request in your UPI app.
                    </span>
                  </div>
                  <div className="flex justify-center w-full py-2">
                    <button
                      className="px-8 py-3 disabled:bg-grey-150 text-white transition-all rounded-full bg-primary-200 hover:bg-white hover:text-primary-200 hover:border-2 hover:border-primary-200"
                      id="pay-collect"
                      type="button"
                    >
                      <span className="font-semibold mt-3">Continue</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modals
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        bankList={bankList}
        bankName={bankName}
        setBankName={setBankName}
        acNumber={acNumber}
        setAcNumber={setAcNumber}
        ifsc={ifsc}
        setIfsc={setIfsc}
        handleNetBanking={handleNetBanking}
        pennyError={pennyError}
        setUpiId={setUpiId}
        setAccountNumber={setAccountNumber}
        accountNumber={accountNumber}
        setIfscNumber={setIfscNumber}
        ifscNumber={ifscNumber}
        upiId={upiId}
        handleUpi={handleUpi}
        upiError={upiError}
        neftBankDetails={neftBankDetails}
        pennyLoading={pennyLoading}
        upiLoading={upiLoading}
        debitCardDetails={debitCardDetails}
        setDebitCardDetails={setDebitCardDetails}
        handleDebitCard={handleDebitCard}
        netBankError={netBankError}
        downNetBanks={downNetBanks}
        downVpas={downVpas}
        totalPay={transactionData?.total_pay}
        disablePay={disablePay}
        setDisablePay={setDisablePay}
        onlyNeft={onlyNeft}
        isLoading={isLoading}
        debitCardError={debitCardError}
        setDebitCardError={setDebitCardError}
        setNetBankError={setNetBankError}
        setPennyError={setPennyError}
        selectedPurposeCode={location?.state?.purpose}
      />
    </motion.div>
  );
};

export default PaymentOptionsPage;
