import RECOIL_KEYS from "./recoilKeys";

export const keysToRemove = [
    RECOIL_KEYS.USER_TRANSACTION_DETAILS,
    RECOIL_KEYS.PURPOSE_CODE,
    RECOIL_KEYS.ENTERED_PROMO_CODE,
    RECOIL_KEYS.CURRENCIES,
    RECOIL_KEYS.SEND_AMOUNT,
    RECOIL_KEYS.SENDING_AMOUNT,
    RECOIL_KEYS.REDEEMED_HOP_COINS,
    RECOIL_KEYS.CONFIRMED_PURPOSE,
  ];