/*eslint-disable */
import React, { useState } from "react";
import clsx from "clsx";
import { IHistoryCard } from "./HistoryCard";
import Accordion from "../Accordion";
import Typograpy from "../Typography";
import Button from "../Button";
import {
  CloseAccordionIcon,
  EyeIcon,
  InfoIcon,
  RightArrowIcon,
  TransferIcon,
} from "../../../assets/Icons";
import Avatar from "../Avatar";
import TX_PROGRESS from "../../../constants";
import Progress from "../Progress";
import { getPaymentDetails, uploadDocs } from "../../../services/history";
import Modal from "../Modal";
import Dropdown from "../Dropdown";
import { useNavigate } from "react-router-dom";
import { RemitLoader } from "../Loader";
import ReactCountryFlag from "react-country-flag";
import { useRecoilState } from "recoil";
import { transactionDetails, transactionId } from "../../../store";
import CountdownTimer from "../Countdown";
import { ToastContainer, toast } from "react-toastify";
import { Axios } from "../../../services/axios";
import download from "downloadjs";
import { formatNumber } from "../../../utils";

const HistoryCard: React.FunctionComponent<IHistoryCard.IProps> = ({
  txNo,
  receiverFname,
  receiverLname,
  date,
  totalPay,
  baseCurrency,
  recepientReceive,
  destinationCurrency,
  countryOfResidence,
  conversionRate,
  status,
  statusToBeShown,
  userFname = "",
  userLname,
  baseCountry = "",
  accNo,
  paymentMode,
  id,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  downloadInvoiceHandler,
  is_fullPaid,
  isTotalPay,
  total_pay,
  advancePay,
  hopFees,
  flagCodeDest,
  isInward,
  taxAndFees,
  promoCode,
  totalPayable,
  purpose,
}) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const [, setTransactionID] = useRecoilState(transactionId);
  const [timer, setTimer] = useState(Date.now());
  const [paymentDetails, setPaymentDetails] = React.useState<any>();
  const [uploadFile, setUploadFile] = React.useState();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [dropdownValue, setDropdownValue] = React.useState("Loan Disbursement Letter");
  const [uploadDocErrMsg, setUploadDocErrMsg] = React.useState("");
  const [transactionData, setTransactionData] = useRecoilState<any>(transactionDetails);
  const [loading, setLoading] = React.useState(false);
  const [showSwift, setShowSwift] = React.useState(false);
  const [showTcs, setShowTcs] = React.useState(false);
  const [currentPurposeCode, setCurrentPurposeCode] = React.useState("");
  const navigate = useNavigate();
  const [transactionNumber, setTransactionNumber] = useRecoilState(transactionId);
  const vendorKey = process.env.REACT_APP_VENDOR_KEY;

  const apiUrl = process.env.REACT_APP_SERVER_ID;
  const handleApiCall = () => {
    setLoading(true);
    getPaymentDetails(currentUser, id)
      .then((res) => {
        setLoading(false);
        setPaymentDetails(res?.data);
        setTransactionNumber(txNo);
        res?.data?.transactionDocs.forEach((doc: any) => {
          if (doc?.doc_name && doc?.doc_name === "Swift Copy") {
            setShowSwift(true);
          }
          if (doc?.doc_name && doc?.doc_name === "tcs") {
            setShowTcs(true);
          }
        });
        const timer_new = res?.data?.time_remaining;
        setTimer(timer_new);
        setCurrentPurposeCode(res?.data?.purposeCode);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleUploadDocs = () => {
    if (uploadFile && dropdownValue) {
      const data: any = new FormData();

      data.append("file", uploadFile);
      data.append("doc_type", dropdownValue.toLowerCase());

      uploadDocs(currentUser, txNo, data)
        .then((res) => {
          // window.console.log('res',res?.data);
          if (res?.data?.success) {
            toast.success(`${res?.data?.Message}`);
            setIsModalOpen(false);
            setShowDropdown(false);
            setDropdownValue("Loan Disbursement Letter");
          }
          // window.location.reload();
        })
        .catch((error) => {
          setIsModalOpen(true);
          setUploadDocErrMsg(error?.response?.data?.error?.type);
        });
    }
  };

  const getDate = (date: string) => {
    const formattedDate =
      new Date(date).toLocaleDateString() +
      "," +
      new Date(date).toLocaleString("en-US", { hour: "numeric", hour12: true, minute: "numeric" });

    return formattedDate.toString();
  };

  const histroyData = [
    {
      header: "Transaction No.",
      value: txNo,
      class: "flex-1.5",
    },
    {
      header: "Recipient",
      value: receiverFname + " " + receiverLname,
      class: "hidden md:flex flex-1.5",
    },
    {
      header: "Date",
      value: getDate(date),
      class: "hidden md:flex flex-1.5",
    },
    {
      header: "Amount",
      value: () => (
        <span className="flex items-center gap-1 lg:gap-4">
          {`${formatNumber(totalPay)} ${baseCurrency}`} <TransferIcon />{" "}
          {`${formatNumber(recepientReceive)} ${destinationCurrency}`}
        </span>
      ),
      class: "flex-2",
    },
    {
      header: "To Country",
      value: countryOfResidence,
      class: "hidden md:flex flex-1",
    },
    {
      header: "FX rate",
      value: conversionRate?.toFixed(2),
      class: "hidden md:flex flex-1",
    },
    {
      header: "Status",
      value: () => (
        <div className="flex flex-col items-center gap-2">
          <Progress
            progressCompleted={TX_PROGRESS[statusToBeShown]?.progressCompleted}
            progressColor={TX_PROGRESS[statusToBeShown]?.progressColor}
            progressContainerClassname=""
          />
          <span
            className={clsx(
              TX_PROGRESS[status]?.value === "Cancelled"
                ? "text-error"
                : TX_PROGRESS[status]?.value === "In progress"
                ? "text-inProcess"
                : TX_PROGRESS[status]?.value === "Completed"
                ? "text-success"
                : "text-inProcess",
              "flex-1",
            )}
          >
            {TX_PROGRESS[statusToBeShown]?.value}
          </span>
        </div>
      ),
      class: "flex-1.5",
    },
  ];

  const accordionTitle = (
    <div className="flex gap-1">
      {histroyData.map((el) => (
        <div
          key={el.header}
          className={clsx("flex flex-col gap-3 text-[16px] items-center", el.class)}
        >
          <Typograpy className="text-[10px] lg:text-[16px] font-bold" tagType="span">
            {el.header}
          </Typograpy>
          <Typograpy className="text-[10px] md:text-[16px] text-grey-600" tagType="span">
            {typeof el.value === "function" ? el.value() : el.value}
          </Typograpy>
        </div>
      ))}
    </div>
  );

  const handleNavigate = () => {
    navigate("/payment-options", {
      state: {
        transactionID: id,
        payAmount: isTotalPay ? JSON.stringify(total_pay) : JSON.stringify(advancePay),
        purpose: currentPurposeCode,
      },
    });
    setTransactionID(txNo);
    sessionStorage.setItem("trxnNum",txNo);
  };

  const openAccordion = (
    <Button
      id="vieew-btn"
      bgColor="bg-transparent md:bg-primary-100"
      className="flex items-center gap-2 py-1 rounded-full md:pl-4 md:pr-1 w-14 md:w-28"
    >
      <Typograpy tagType="span" className="hidden md:block text-primary">
        View
      </Typograpy>
      <EyeIcon />
    </Button>
  );
  const closeAccordion = (
    <Button
      id="close-accordin-btn"
      bgColor="bg-transparent"
      className="flex items-center gap-2 py-1 transition-all rounded-full hover:rotate-180 w-14 md:ml-14"
    >
      <CloseAccordionIcon className="rotate-180" />
    </Button>
  );

  const expandedDetails = (
    avatarName: string,
    name: string,
    country: string,
    payment: number | string,
    flagCode?: any,
    accNo?: number | null,
  ) => {
    return (
      <div className="flex items-center gap-8">
        <div className="hidden md:block">
          <Avatar name={avatarName} />
        </div>
        <div className="flex flex-col">
          <Typograpy tagType="span" className="text-md md:text-xl">
            {name}
          </Typograpy>
          <Typograpy tagType="span" className="text-sm lg:text-lg text-grey-700">
            <>
              <ReactCountryFlag
                countryCode={flagCode}
                svg
                style={{
                  width: "2em",
                  height: "2em",
                  marginRight: "4px",
                }}
                title="US"
              />
              {/* <img src={`/images/png/INR.png`} alt="" width="35px" height="35px" /> */}
              {country}
            </>
          </Typograpy>
          {accNo && (
            <div className="flex gap-1">
              <Typograpy tagType="span" className="lg:text-[14px] text-[10px] font-bold">
                A/C No.
              </Typograpy>
              <Typograpy tagType="span" className="lg:text-[14px] text-[10px]">
                {accNo}
              </Typograpy>
            </div>
          )}
        </div>
        <Typograpy tagType="span" className="hidden md:block text-[36px] font-bold">
          {payment}
        </Typograpy>
      </div>
    );
  };

  const detailedTxDetails = (
    text: string,
    amount: string | number,
    currency: string,
    operator?: "+" | "-" | "*" | "/",
  ) => (
    <div className="flex">
      {operator && <Typograpy tagType="span">{`(   ${operator} )`}</Typograpy>}
      <Typograpy tagType="span" className="ml-2">
        {text}
      </Typograpy>
      <Typograpy tagType="span" className="ml-2 font-bold ">
        {`${amount} ${currency}`}
      </Typograpy>
    </div>
  );

  const UploadComponent = () => {
    return (
      <div id="uploadDocs" className="flex items-center px-12 py-3 rounded bg-[#FFF7EB] space-x-5">
        <InfoIcon />
        <span>Upload missing documents</span>
        <button onClick={() => setIsModalOpen(true)} className="font-bold text-blue-500">
          Upload
        </button>
      </div>
    );
  };

  const downloadPdf = () => {
    setLoading(true);
    Axios.get(
      `${apiUrl}/remit/user/${vendorKey}/transaction/${paymentDetails.transaction_id}/getA2FormDashboard`,
      {
        headers: {
          "Access-Control-Allow-Origin": "true",
          "Content-Type": "application/pdf",
          Authorization: `${currentUser.idToken}`,
          "partner-type": "moneyhop",
        },
        responseType: "blob",
      },
    )
      .then((response) => {
        setLoading(false);
        const content = response.headers["content-type"];
        download(response.data, "download_A2.pdf", content);
      })
      .catch((error) => {
        setUploadDocErrMsg(error?.response?.data?.error?.type || "Failed to fetch A2 Form");
      })
      .finally(() => setLoading(false));
  };

  const downloadForm = (docName: string, docType: string) => {
    setLoading(true);
    Axios.get(
      `${apiUrl}/remit/user/get/documents/${vendorKey}/id/${paymentDetails.transaction_id}/doc/${docType}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "true",
          "Content-Type": "application/pdf",
          Authorization: `${currentUser.idToken}`,
          "partner-type": "moneyhop",
        },
        responseType: "blob",
      },
    )
      .then((response) => {
        const content = response.headers["content-type"];
        download(response.data, `${docName}.pdf`, content);
      })
      .catch((error) => {
        setUploadDocErrMsg(error?.response?.data?.error?.type || `Failed to download ${docName}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <Accordion
        title={accordionTitle}
        openAccordion={openAccordion}
        closeAccordion={closeAccordion}
        customClassname="px-3 md:px-8 py-3 md:py-4"
        handleOnClick={handleApiCall}
      >
        {!loading ? (
          <div className="flex flex-col mb-2">
            <div className="flex items-center">
              {expandedDetails(
                `${userFname?.charAt(0)}${userLname?.charAt(0)}`,
                `${userFname} ${userLname}`,
                baseCountry,
                formatNumber(totalPay),
                "IN",
              )}
              <div className="flex items-center justify-center w-12 h-12 mx-6 rounded-full bg-primary-100 md:w-16 md:h-16 md:mx-10">
                <RightArrowIcon />
              </div>
              {expandedDetails(
                `${receiverFname.charAt(0)}${receiverLname.charAt(0)}`,
                `${receiverFname} ${receiverLname}`,
                countryOfResidence,
                formatNumber(recepientReceive),
                flagCodeDest,
                accNo,
              )}
            </div>
            <div className="hidden w-full gap-6 px-4 items-center py-6 mt-8 rounded md:flex-wrap bg-primary-100 md:flex">
              {hopFees && detailedTxDetails("Fees: ", formatNumber(hopFees), baseCurrency, "+")}
              {taxAndFees &&
                detailedTxDetails("Taxes and Fees:", formatNumber(taxAndFees), baseCurrency, "+")}
              {promoCode && detailedTxDetails("PromoCode:", promoCode, baseCurrency, "-")}
              {paymentMode && detailedTxDetails("Payment Mode:", paymentMode, "")}
              {totalPayable &&
                detailedTxDetails("Amount Payable:", formatNumber(totalPayable), baseCurrency)}
              {statusToBeShown === "REQUIRE_MORE_DOCUMENTS" && <UploadComponent />}
            </div>
            <div className="flex mt-8 w-full justify-between">
              {is_fullPaid && (
                <>
                  <div className="flex items-center">
                    <Typograpy tagType="span" className="mx-3 font-bold ">
                      Download
                    </Typograpy>
                    {showSwift && (
                      <span
                        onClick={() =>
                          downloadForm(
                            `${userFname}_${recepientReceive}_${destinationCurrency}`,
                            "swift",
                          )
                        }
                        className="text-primary mx-1 cursor-pointer ml-2"
                      >
                        Swift
                      </span>
                    )}
                    <span onClick={downloadPdf} className="text-primary mx-1 cursor-pointer ml-2">
                      A2 Form
                    </span>
                    {showTcs && (
                      <span
                        onClick={() => downloadForm("tcs", "tcs")}
                        className="text-primary mx-1 cursor-pointer ml-2"
                      >
                        TDS Certificate
                      </span>
                    )}
                  </div>

                  {(!paymentDetails?.isAwaitingPayment ||
                    statusToBeShown !== ("AWAITING_PAYMENT" || "AWAITING_FULL_PAYMENT")) && (
                    <button className="px-12 py-3 bg-emerald-600 text-white text-[20px] font-bold rounded">
                      Paid
                    </button>
                  )}
                </>
              )}
            </div>
            <div
              id="lastRow"
              className="flex flex-col mt-6 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0"
            >
              {(paymentDetails?.isAwaitingPayment ||
                status === "AWAITING_PAYMENT" ||
                statusToBeShown === "AWAITING_FULL_PAYMENT") && (
                <div id="countdown" className="flex flex-col justify-center">
                  <div id="line1" className="flex items-center">
                    <span className="font-semibold text-[22px] text-red-500">
                      Please complete your payment within:
                    </span>
                    <div>
                      <CountdownTimer timeInSeconds={timer} />
                    </div>
                  </div>
                  <span id="line2" className="text-[20px] font-light">
                    Otherwise your transaction may be put on hold
                  </span>
                </div>
              )}
              <div
                id="rightSide"
                className="flex flex-col space-x-8 space-y-3 lg:flex-row lg:space-y-0"
              >
                {paymentDetails?.isAwaitingPayment ||
                status === "AWAITING_PAYMENT" ||
                statusToBeShown === "AWAITING_FULL_PAYMENT" ? (
                  <button
                    onClick={() => {
                      sessionStorage.setItem("transactionID", id);
                      setTransactionData({
                        ...transactionData,
                        total_pay: total_pay,
                        id_transaction: id,
                      });
                      !isInward ? handleNavigate() : navigate("/inward/payment-options");
                    }}
                    className="px-12 py-3 bg-[#363A42] text-white text-[20px] font-bold rounded"
                  >
                    Pay Now
                  </button>
                ) : paymentDetails?.isRemainingAmountPay ? (
                  <button
                    onClick={() => {
                      setTransactionData({
                        ...transactionData,
                        total_pay: total_pay && total_pay - advancePay,
                        id_transaction: id,
                      });
                      sessionStorage.setItem("transactionID", id);
                      setTransactionID(txNo);
                      navigate("/payment-options", {
                        state: {
                          transactionID: id,
                          payAmount: total_pay && total_pay - advancePay,
                        },
                      });
                    }}
                    className="px-12 py-3 bg-[#363A42] text-white text-[20px] font-bold rounded"
                  >
                    Pay Now
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center w-[120px] ml-[150px] lg:w-[330px] lg:ml-[600px]">
            <RemitLoader />
          </div>
        )}
        <Modal
          animation={true}
          visible={isModalOpen}
          closeHandler={() => setIsModalOpen(false)}
          header="Upload Supporting Docs"
          modalClass="rounded-md pt-1 pb-2"
          headerClass="text-lg"
        >
          <div className="mt-3">
            <Typograpy tagType="span">Please upload scanned image of the document</Typograpy>
            <div className="flex w-full">
              <Dropdown
                showDropdown={showDropdown}
                dropdownButtonOnClick={() => setShowDropdown((prev) => !prev)}
                dropdownLabel="Documents"
                list={[
                  "Loan Disbursement Letter",
                  "Payment Confirmation",
                  "Self Declaration",
                  "University Letter",
                  "Travel Tickets",
                  "Bank Statement",
                  "Visa",
                  "Others",
                ]}
                dropdownClassname="w-full p-0"
                dropdownBodyClassname="w-auto"
                dropdownValue={dropdownValue}
                listItemOnClick={(e) => {
                  e.stopPropagation();
                  setDropdownValue(e.target.textContent);
                  setShowDropdown(false);
                }}
              />
            </div>
            <form encType="multipart/form-data" action="">
              <div>
                <input
                  accept="image/*"
                  id="outlined-button-file2"
                  //  multiple
                  type="file"
                  onChange={(e: any) => {
                    setUploadFile(e.target.files[0]);
                  }}
                />
              </div>
            </form>
            {uploadDocErrMsg && (
              <Typograpy tagType="span" className="text-error">
                {uploadDocErrMsg}
              </Typograpy>
            )}
            <div className="flex items-center justify-end mt-2 space-x-3">
              <Button
                id="Yes-btn-document"
                bgColor="bg-primary"
                fontStyle={{
                  fontFamily: "font-ssp",
                  fontSize: "text-base",
                  fontWeight: "font-bold",
                  fontColor: "text-white",
                }}
                borderStyle={{
                  borderRadius: "rounded",
                }}
                className="px-6 py-2"
                onClickHandler={handleUploadDocs}
              >
                Yes
              </Button>
              <Button
                id="no-upload-doc-btn"
                bgColor="bg-primary"
                fontStyle={{
                  fontFamily: "font-ssp",
                  fontSize: "text-base",
                  fontWeight: "font-bold",
                  fontColor: "text-white",
                }}
                borderStyle={{
                  borderRadius: "rounded",
                }}
                className="px-6 py-2"
                onClickHandler={() => setIsModalOpen(false)}
              >
                No
              </Button>
            </div>
          </div>{" "}
        </Modal>
      </Accordion>
    </>
  );
};

export default HistoryCard;
