import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import mixpanel from "mixpanel-browser";
import Cookies from "universal-cookie";

import Button from "../../ui/Button";
import { Sidebar } from "../../ui/Sidebar";
import IProtectedPageLayout from "./ProtectedPgaeLayout";
import { CoinStar, KnowRates } from "../../../assets/Icons";
import { openSidebar, userDetails } from "../../../store";
import { clearTransactionStates } from "../../../services/transactionDetails/clearTransactionStatus";
import { keysToRemove } from "../../../constants/keysToRemove";

const cookies = new Cookies();

export const GAP_FROM_SIDEBAR = "mx-5 md:ml-10 md:mr-14";

const ProtectedPageLayout: React.FunctionComponent<IProtectedPageLayout.IProps> = ({
  hopCoins,
  children,
}) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isShowHeader, setIsShowHeader] = useState(true);
  const openSidebars = useRecoilValue(openSidebar);
  const navigate = useNavigate();
  const location = useLocation();
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;

  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });

  const handleLogoutClick = () => {
    mixpanel.track("Logout");
    localStorage.clear();
    sessionStorage.clear();
    clearTransactionStates(keysToRemove);
    cookies.remove("XSRF-TOKEN");
  };

  useEffect(() => {
    if (openSidebars) {
      setShowSidebar(true);
    } else setShowSidebar(false);
  }, [openSidebars]);

  const userData = useRecoilValue(userDetails);

  const closeSidebar = () => {
    if (showSidebar) {
      setShowSidebar(false);
    }
  };

  React.useEffect(() => {
    location.pathname === "/send-money" ||
    location.pathname === "/user-documents" ||
    location.pathname === "/recipient-details" ||
    location.pathname === "/payment-options" ||
    location.pathname === "/payment-method" ||
    location.pathname === "/user-details" ||
    location.pathname === "/review"
      ? setIsShowHeader(false)
      : setIsShowHeader(true);
  });

  return (
    <section className={clsx("flex bg-primary-800 h-full", showSidebar && "!bg-mask relative")}>
      <aside
        id="sidebar"
        style={{ zoom: "90%" }}
        className={clsx(
          "hidden md:block md:w-auto w-[75%] h-full",
          showSidebar && "!block absolute z-50",
        )}
      >
        <Sidebar
          userName={`${userData?.firstName} ${userData?.lastName}`}
          closeSidebar={closeSidebar}
          showSidebar={showSidebar}
          isPrime={userData?.isPrime}
          handleLogoutClick={handleLogoutClick}
        />
      </aside>
      <div
        onClick={closeSidebar}
        className={clsx(
          showSidebar && "opacity-[0.75]",
          "flex flex-col flex-1 md:mt-7 md:my-8 w-screen h-screen overflow-y-auto",
        )}
      >
        {isShowHeader && (
          <div
            className={clsx(
              location?.pathname === "/" ||
                location.pathname === "/history" ||
                location.pathname === "/rewards" ||
                location.pathname === "/recipients" ||
                location.pathname === "/fxrates" ||
                location?.pathname === "/support"
                ? "flex"
                : "hidden",
              "md:flex flex justify-between items-center gap-y-4 md:flex-nowrap mt-4 md:mt-0",
              GAP_FROM_SIDEBAR,
            )}
          >
            <div
              onClick={() => setShowSidebar(true)}
              className="flex flex-1 gap-1.5 flex-col md:hidden"
            >
              {Array(3)
                .fill(undefined)
                .map((_, id) => (
                  <div className="w-6 border rounded border-grey-800" key={id}></div>
                ))}
            </div>
            <div id="headerLeft" className="items-center justify-between w-full md:flex lg:flex">
              <div id="date/time" className="hidden md:flex">
                {/* <span className="font-bold text-[28px] font-ssp">{longMonth}</span>
                <span className="ml-1 font-bold text-[28px] font-ssp">{date}</span>
                <span className=" text-[28px] font-ssp">,</span>
                <span className="ml-1 font-bold text-[28px] font-ssp">{year}</span>
                <span className="ml-3 text-[28px] font-ssp">{time}</span> */}
              </div>
              <div
                id="headerRight"
                onClick={() => {
                  navigate("/fxrates");
                  mixpanel.track("Fx Chart");
                }}
                className="flex items-center p-2 ml-2 space-x-5 transition-all rounded-lg cursor-pointer group hover:bg-blue-200 md:ml-0"
              >
                <span className="font-semibold text-[14px] lg:text-[18px] flex flex-col items-center">
                  Fx Rates
                  <br />
                  <span className="text-red-500 text-[7px] lg:text-[16px] font-bold group-hover:scale-110 group-hover:text-red-400 transition-all animate-pulse">
                    LIVE
                  </span>
                </span>
                <div className="float-right">
                  <KnowRates />
                </div>
                <span className="font-bold text-[24px] hidden md:flex"> | </span>
              </div>
            </div>
            <div className="flex items-center ml-8 scale-75 lg:ml-0 lg:scale-100">
              <div id="hopcoins" className="flex pr-3 group">
                <Button
                  id="hopCoins"
                  bgColor="bg-rewardButton"
                  className="w-36 h-12 rounded-[73px] text-white ml-6 relative bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 hover:from-blue-700 hover:via-blue-500 hover:to-blue-300 transition-all hover:transition-all hover:shadow-lg hover:shadow-blue-500 hover:border-2 hover:border-blue-500"
                  onClickHandler={() => {
                    navigate("/rewards");
                    mixpanel.track("Hop coins_dashboard");
                  }}
                >
                  <span className="text-[22px] font-bold text-white">{hopCoins}</span>
                  <div className="absolute duration-500 -translate-x-1/2 group-hover:rotate-180 left-full">
                    <CoinStar />
                  </div>
                </Button>
              </div>
              {/* <div id="notification" className="pr-3">
                <Button
                  id="notification-number"
                  bgColor="bg-primary"
                  className="w-12 h-12 relative rounded-[25px] ml-6 md:ml-12"
                  onClickHandler={() => {
                    mixpanel.track("Notification bell_dashboard");
                  }}
                >
                  <div className="">
                    <NotificationIcon />
                  </div>
                  <div
                    id="numberOfNotification"
                    className="absolute w-5 h-5 rounded-[14px] top-0 text-white bg-notificationNumberColor -top[10%] left-3/4 flex items-center justify-center"
                  >
                  </div>
                </Button>
              </div> */}
            </div>
          </div>
        )}
        {children}
      </div>
    </section>
  );
};

export default ProtectedPageLayout;
