import { atom } from "recoil";
import { loadState, saveState } from "../services/persistence/persistence";

import RECOIL_KEYS from "../constants/recoilKeys";
import {
  AdditionalDocList,
  EsignedStatus,
  KycDetails,
  RecipientDetais,
  UserDetails,
  UserTransactionDetails,
  Categories,
  UploadDocument,
  CurrenciesDetails,
  ConfirmedPurposeCode,
} from "./store";

export const userDetails = atom({
  key: RECOIL_KEYS.USER_DETAILS,
  default: {} as UserDetails,
});

export const searchValue = atom({
  key: RECOIL_KEYS.SEARCH_VALUE,
  default: "",
});

export const recipientDetails = atom({
  key: RECOIL_KEYS.RECIPIENT_DETAILS,
  default: [] as RecipientDetais,
});

export const transactionDetails = atom({
  key: RECOIL_KEYS.USER_TRANSACTION_DETAILS,
  default: loadState<UserTransactionDetails>(RECOIL_KEYS.USER_TRANSACTION_DETAILS)||{} as UserTransactionDetails,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        saveState(RECOIL_KEYS.USER_TRANSACTION_DETAILS, newValue);
      });
    },
  ],
});

export const purposeCode = atom({
  key: RECOIL_KEYS.PURPOSE_CODE,
  default: loadState<string>(RECOIL_KEYS.PURPOSE_CODE)??"",
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        saveState(RECOIL_KEYS.PURPOSE_CODE, newValue);
      });
    },
  ],
});

export const enteredPromoCode = atom({
  key: RECOIL_KEYS.ENTERED_PROMO_CODE,
  default: loadState<string>(RECOIL_KEYS.ENTERED_PROMO_CODE)??"",
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        saveState(RECOIL_KEYS.ENTERED_PROMO_CODE, newValue);
      });
    },
  ],
});

export const currencies = atom({
  key: RECOIL_KEYS.CURRENCIES,
  default: loadState<CurrenciesDetails>(RECOIL_KEYS.CURRENCIES)??{sendingCurrency:"INR",receiveCurrency:"USD"},
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        saveState(RECOIL_KEYS.CURRENCIES, newValue);
      });
    },
  ],
});

export const sendAmountState = atom({
  key: RECOIL_KEYS.SEND_AMOUNT,
  default:loadState<number>(RECOIL_KEYS.SEND_AMOUNT)??5000,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        saveState(RECOIL_KEYS.SEND_AMOUNT, newValue);
      });
    },
  ],
});

export const sendingAmountState = atom({
  key: RECOIL_KEYS.SENDING_AMOUNT,
  default:loadState<number>(RECOIL_KEYS.SENDING_AMOUNT)??5000,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        saveState(RECOIL_KEYS.SENDING_AMOUNT, newValue);
      });
    },
  ],
});

export const redeemedHopCoins = atom({
  key: RECOIL_KEYS.REDEEMED_HOP_COINS,
  default: loadState<any>(RECOIL_KEYS.REDEEMED_HOP_COINS)??0,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        saveState(RECOIL_KEYS.REDEEMED_HOP_COINS, newValue);
      });
    },
  ],
});

export const kycDetails = atom({
  key: RECOIL_KEYS.KYC_DETAILS,
  default: {} as KycDetails,
});

export const additionalData = atom({
  key: RECOIL_KEYS.ADDITIONAL_DATA,
  default: [] as Categories,
});

export const additionalDocs = atom({
  key: RECOIL_KEYS.ADDITIONAL_DOC,
  default: {} as UploadDocument,
});

export const confirmedPurpose = atom({
  key: RECOIL_KEYS.CONFIRMED_PURPOSE,
  default: loadState<ConfirmedPurposeCode>(RECOIL_KEYS.CONFIRMED_PURPOSE)??{ value: "Select the Purpose", code: "" },
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        saveState(RECOIL_KEYS.CONFIRMED_PURPOSE, newValue);
      });
    },
  ],
});

export const isFirstTimeUser = atom({
  key: RECOIL_KEYS.FIRST_LOGIN,
  default: false,
});

export const openSidebar = atom({
  key: RECOIL_KEYS.OPEN_SIDEBAR,
  default: false,
});

export const transactionLeadIDS = atom({
  key: RECOIL_KEYS.TRANSACTION_LEADIDS,
  default: [] as any,
});

export const userAdditionalDocs = atom({
  key: RECOIL_KEYS.USER_ADDITIONAL_DOC,
  default: {} as AdditionalDocList,
});

export const isInwardTransaction = atom({
  key: RECOIL_KEYS.INWARD_TRANSACTION,
  default: false,
});

export const isSignupSuccess = atom({
  key: RECOIL_KEYS.SIGNUP_SUCCESS,
  default: false,
});

export const isEsigned = atom({
  key: RECOIL_KEYS.ESIGN_STATUS,
  default: {} as EsignedStatus,
});

export const transactionId = atom({
  key: RECOIL_KEYS.TRANSACTION_ID,
  default: "" as string,
});
