import React from "react";
import { useSpring, animated, useChain, useSpringRef } from "@react-spring/web";
import CustomSwiper from "../../swiper";
import { FooterValues } from "./Footer";

const FooterView: React.FC<FooterValues> = () => {
  const bannerRef: any = useSpringRef();
  const loginRef: any = useSpringRef();

  const bannerStyle = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    ref: bannerRef,
  });

  useChain([loginRef, bannerRef]);

  const signupBanners = ["signUpBanner1", "signUpBanner2", "signUpBanner3", "signUpBanner4"];

  return (
    <animated.div
      style={bannerStyle}
      id="right"
      className="flex flex-col items-center h-full lg:py-20 justify-evenly lg:w-7/12"
    >
      <div className="flex flex-col items-start justify-center md:w-full px-5 lg:px-20 w-[80%]">
        <span className="font-sans font-bold text-white text-[26px] lg:text-[36px] mt-5">
          HOPRemit
        </span>
        <span className="lg:text-[24px] text-[18px] text-gray-200 font-sans mt-[5%] sm:mt-0">
          Send money abroad in a digital, economical and seamless manner
        </span>
      </div>
      <div id="4banners" className="hidden w-full px-10 lg:flex justify-evenly lg:mt-6">
        {signupBanners.map((item, index) => {
          return (
            <div key={index} className="transition-all hover:scale-110">
              <span className="">
                <img src={`/images/svg/${item}.svg`} />
              </span>
            </div>
          );
        })}
      </div>
      <div className="flex md:hidden flex-col items-center w-full mt-10">
        <CustomSwiper />
      </div>
      <div className="flex justify-center w-full mt-10 md:mt-20">
        <div className="flex items-center flex-col sm:flex-row justify-between w-4/5 text-opacity-100 sm:bg-black sm:border sm:border-black rounded-lg sm:shadow-lg sm:bg-opacity-40 sm:shadow-gray-800">
          <div className="flex flex-col items-center justify-center bg-black sm:bg-transparent sm:border-0 sm:shadow-none border-black shadow-lg bg-opacity-[25%] shadow-gray-800 sm:w-1/3 w-full my-4 sm:my-0 px-3 py-4 opacity-100 lg:px-3">
            <span className="text-white font-bold text-[18px] lg:text-[32px] text-opacity-100">
              {"₹2,500 Cr+"}
            </span>
            <span className="text-white font-semibold text-[10px] lg:text-[20px] text-opacity-100">
              {"Remitted"}
            </span>
          </div>
          <div className="flex flex-col items-center justify-center px-3 py-4 border-x-0 sm:border-white opacity-100 lg:px-3 sm:border-x-2 bg-black sm:bg-transparent sm:border-0 sm:shadow-none border-black shadow-lg bg-opacity-[25%] shadow-gray-800 sm:w-1/3 w-full my-3 ">
            <span className="text-white font-bold text-[18px] lg:text-[32px] text-opacity-100">
              {"60+"}
            </span>
            <span className="text-white font-semibold text-[10px] lg:text-[20px] text-opacity-100">
              {"Countries Served"}
            </span>
          </div>
          <div className="flex flex-col items-center justify-center bg-black sm:bg-transparent sm:border-0 sm:shadow-none border-black shadow-lg bg-opacity-[25%] shadow-gray-800 sm:w-1/3 w-full my-3 px-3 py-4 sm:border-r-2 sm:border-white opacity-100 lg:px-3">
            <span className="text-white font-bold text-[18px] lg:text-[32px] text-opacity-100">
              {"40,000+"}
            </span>
            <span className="text-white font-semibold text-[10px] lg:text-[20px] text-opacity-100">
              {"Happy Customers"}
            </span>
          </div>
          <div className="flex flex-col items-center justify-center bg-black sm:bg-transparent sm:border-0 sm:shadow-none border-black shadow-lg bg-opacity-[25%] shadow-gray-800 sm:w-1/3 w-full my-3 px-3 py-4 opacity-100 lg:px-3">
            <span className="text-white font-bold text-[18px] lg:text-[32px] text-opacity-100">
              {"50,000+"}
            </span>
            <span className="text-white text-center font-semibold text-[10px] lg:text-[20px] text-opacity-100">
              {"Transactions Processed"}
            </span>
          </div>
        </div>
      </div>
      {/* {showOffer && (
        <div className="flex justify-center w-full mt-4 iteme-center lg:mt-16">
          <div className="flex items-center w-4/5 px-5 py-2 rounded-lg shadow-xl justify-evenly lg:px-10 bg-gradient-to-b from-amber-400 to-amber-700">
            <div className="w-1/5 -mt-2">
              <img src="/images/png/priceTag.png" alt="" />
            </div>
            <div className="flex flex-col justify-between w-4/5">
              <div className="flex items-center space-x-5">
                <span className="text-white text-[12px] md:text-sm font-extrabold lg:text-[32px] font-lato">
                  Special Offer
                </span>
                <div className="px-5 py-2 text-[12px] md:text-sm font-extrabold bg-white rounded-full text-amber-500">
                  Use Code - SUPERHOP
                </div>
              </div>
              <span className="text-white text-[10px] mt-4 md:mt-0 lg:text-[24px] font-ssp">
                Get 100% Off on your convenience fee for the first time user.
              </span>
            </div>
          </div>
        </div>
      )} */}
    </animated.div>
  );
};

export default FooterView;
