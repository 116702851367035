/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";

import {
  ConvenienceFeeIcon,
  DivisionIcon,
  DownIcon,
  Equal,
  InfoIcon,
  Minus,
  Plus,
  RightNavigationIcon,
} from "../../assets/Icons";
import Button from "../../components/ui/Button";
import { transactionDetails } from "../../store";
import { convertToTwoDigit, formatNumber, getSendMoneyErrorMessage } from "../../utils";
import ISendMoney from "./sendMoney";
import mixpanel from "mixpanel-browser";
import ErrorField from "../../components/ui/ErrorField";

const PaymentDetailsCard: React.FC<ISendMoney.IPaymentDetailsProps> = ({
  removePromocode,
  selectedDeliveryOption,
  onClickHandler,
  sendingAmount,
  sendingCurrency,
  receiveCurrency,
  isInward,
  purposeErrorInward,
  error,
  loading,
}) => {
  const transactionSummaryData = useRecoilValue(transactionDetails);
  const [sendStep, setSendStep] = useState<boolean>(false);
  const [taxStep, setTaxStep] = useState<boolean>(false);
  const [discountStep, setDiscountStep] = useState<boolean>(false);
  const hopCoinsApplied: any = transactionSummaryData?.hop_coins;
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });

  const tcs = convertToTwoDigit(transactionSummaryData?.tcs);
  const service_tax = convertToTwoDigit(transactionSummaryData?.service_tax);
  const nostro_charges = convertToTwoDigit(transactionSummaryData?.nostro_charges);
  // const convenience_fees = convertToTwoDigit(transactionSummaryData?.hop_fees);
  const taxPrices = convertToTwoDigit(service_tax + nostro_charges + tcs);

  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    sendStep || taxStep || discountStep
      ? ref?.current?.scrollIntoView({ behavior: "smooth" })
      : null;
  }, [sendStep, taxStep, discountStep]);
  return (
    <>
      <div className="relative sm:mx-3 flex items-center max-w-[720px] justify-start mt-2 mb-3 text-sm font-bold text-black lg:hidden">
        Review
      </div>
      <div className="flex flex-col w-4/5 group lg:flex-1 md:mx-20 bg-white py-[22px] lg:py-[18px] lg:px-9 px-6 justify-center m-auto">
        <div className="hidden lg:flex text-sm font-bold text-black mb-[30px]">Payment Summary</div>
        <div className="flex items-center justify-between mb-9">
          <div className="flex items-center justify-start flex-1 lg:pr-6">
            <div className="flex flex-col items-start justify-between lg:space-y-6">
              <div className="text-3xl font-normal text-black mb-[6px] lg:mb-0">
                {formatNumber(transactionSummaryData.user_send) ?? 1000}
              </div>
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center w-8 h-8 mr-3 lg:mr-7">
                  <img
                    src={`/images/png/${transactionSummaryData.base_currency ?? "INR"}.png`}
                    alt="profile-image"
                    className="rounded-[200px] w-full h-full"
                  />
                </div>
                <div className="font-bold text-primary lg:text-[18px] text-sm">
                  {transactionSummaryData.base_currency ?? "INR"}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary-450 ">
            <RightNavigationIcon />
          </div>
          <div className="flex items-center justify-end flex-1 lg:pl-6">
            <div className="flex flex-col items-start justify-between lg:space-y-6">
              <div className="text-3xl font-normal text-black mb-[6px] lg:mb-0">
                {formatNumber(transactionSummaryData.reciepient_recieve) ?? 10.17}
              </div>
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center w-8 h-8 mr-3 lg:mr-7">
                  <img
                    src={`/images/png/${transactionSummaryData.dest_currency ?? "USD"}.png`}
                    alt="profile-image"
                    className="rounded-[200px] w-full h-full"
                  />
                </div>
                <div className="font-bold text-primary lg:text-[18px] text-sm">
                  {transactionSummaryData.dest_currency ?? "USD"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5 text-sm font-bold text-black">Calculation</div>
        <div>
          <div className="flex items-center justify-between mb-8">
            <div className="flex flex-col flex-1 -ml-2">
              <div
                onClick={() => setSendStep(!sendStep)}
                className="flex items-center justify-between mb-0 cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-2 text-xs group-hover:text-[16px] transition-all font-bold text-black lg:text-sm">
                  <div className="mr-3 transition-all group-hover:-rotate-90">
                    <DownIcon />
                  </div>
                  You Send
                </div>
                <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                  <div className="text-xs font-bold text-black lg:text-base group-hover:text-[16px] transition-all">
                    {formatNumber(transactionSummaryData.user_send)}
                  </div>
                  <div className="text-xs font-bold text-black lg:text-sm group-hover:text-[16px] transition-all">
                    {isInward ? "GBP" : "INR"}
                  </div>
                </div>
              </div>
              {sendStep === true && (
                <div className="-ml-1">
                  <div className="flex items-center justify-between mt-5 ml-2">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Equal />
                      </div>
                      Conversion amount
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-base">
                        {isInward
                          ? formatNumber(transactionSummaryData?.reciepient_recieve)
                          : formatNumber(transactionSummaryData?.conversion_amount)}
                      </div>
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-sm">
                        INR
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between mb-8">
            <div className="bg-grey-1350 w-[30px] h-[30px] rounded-full flex justify-center items-center -ml-2">
              <DivisionIcon />
            </div>
            <div className="flex items-center justify-between flex-1 ml-5">
              <div className="text-xs font-bold lg:text-sm text-grey-150">Exchange rate</div>
              <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                <div className="text-xs font-bold lg:text-base text-grey-150">
                  {transactionSummaryData?.conversion_rate?.toFixed(2)}
                </div>
                <div className="text-xs font-bold lg:text-sm text-grey-150">INR</div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between mb-8">
            <div className="flex flex-col flex-1 -ml-2">
              <div
                onClick={() => (isInward ? null : setTaxStep(!taxStep))}
                className="flex items-center justify-between cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center text-xs group-hover:text-[16px] transition-all font-bold text-black lg:text-sm">
                  <div className="mr-3 transition-all group-hover:-rotate-90 ">
                    <DownIcon />
                  </div>
                  Taxes and fees
                </div>
                <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                  <div className="text-xs font-bold text-black lg:text-base group-hover:text-[16px] transition-all">
                    {formatNumber(taxPrices)}
                  </div>
                  <div className="text-xs font-bold text-black lg:text-sm group-hover:text-[16px] transition-all">
                    {isInward ? "GBP" : "INR"}
                  </div>
                </div>
              </div>
              {taxStep === true && (
                <div className="mt-5 ml-1">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Plus />
                      </div>
                      GST
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:text-sm">
                        {formatNumber(transactionSummaryData?.service_tax)}
                      </div>
                      <div className="text-xs font-normal text-black lg:text-sm">INR</div>
                    </div>
                  </div>
                  {/* <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Plus />
                      </div>
                      Convenience Fees
                      <ConvenienceFeeIcon />
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      {isInward ? (
                        <div className="text-xs font-normal text-black lg:text-sm">
                          <span
                            className={
                              isInward
                                ? "mr-2 line-through text-[#FF7A00] font-bold"
                                : "mr-2 line-through"
                            }
                          >
                            {transactionSummaryData?.hop_fees}
                          </span>
                          <span className="text-xs font-normal text-black lg:text-sm">0</span>
                        </div>
                      ) : (
                        <div className="text-xs font-normal text-black lg:text-sm">
                          {transactionSummaryData?.hop_fees}
                        </div>
                      )}
                      <div
                        className={
                          isInward
                            ? "text-xs font-bold text-[#FF7A00] lg:text-sm"
                            : "text-xs font-normal text-black lg:text-sm"
                        }
                      >
                        {isInward ? "GBP" : "INR"}
                      </div>
                    </div>
                  </div> */}
                  <div className="flex items-center justify-between mb-5">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Plus />
                      </div>
                      <div className="flex items-center space-x-2">
                        {transactionSummaryData?.purposeCode === "S1301" || transactionSummaryData?.purposeCode === "S1302" || transactionSummaryData?.hop_fees===0 ? (
                          <span>Bank Fee</span>
                        ) : (
                          <span>Bank & Convenience Fees</span>
                        )}
                        {/* {transactionSummaryData?.purposeCode === "S1301" ||
                          (transactionSummaryData?.purposeCode === "S1302" && (
                            <div className="flex items-center space-x-1 text-white rounded cursor-pointer group">
                              <span className="px-2 py-1 rounded-lg bg-primary text-white text-[14px]">
                                Bene
                              </span>
                              <InfoIcon />
                            </div>
                          ))} */}
                      </div>
                    </div>
                    {/* <div className="absolute hidden h-auto p-4 bg-white rounded-lg shadow-xl group-hover:flex min-w-4/5 font-lato lg:-translate-x-20">
                      The transaction under the selected purpose code would be processed under"BEN"
                      category, which means that NOSTRO related charges would be borne by the
                      beneficiary only. You can see a deduction from 5 to 25 USD equivalent at the
                      time credit depending on the amount and the currency.
                    </div> */}
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-base">
                        {formatNumber(transactionSummaryData?.nostro_charges)}
                      </div>
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-sm">
                        INR
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Plus />
                      </div>
                      TCS
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-base">
                        {formatNumber(tcs)}
                      </div>
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-sm">
                        INR
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between mb-8">
            <div className="flex flex-col flex-1 -ml-2">
              <div
                onClick={() => (isInward ? null : setDiscountStep(!discountStep))}
                className="flex items-center justify-between cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center text-xs group-hover:text-[16px] transition-all font-bold text-black lg:text-sm">
                  <div className="mr-3 transition-all group-hover:-rotate-90 ">
                    <DownIcon />
                  </div>
                  Total Discount
                </div>
                <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                  <div className="text-xs font-bold text-black lg:text-base group-hover:text-[16px] transition-all">
                    {isInward
                      ? "0"
                      : transactionSummaryData?.promoCodeDetails?.promoCodeDiscount +
                        hopCoinsApplied / 2}
                  </div>
                  <div className="text-xs font-bold text-black lg:text-sm group-hover:text-[16px] transition-all">
                    {isInward ? "GBP" : "INR"}
                  </div>
                </div>
              </div>
              {discountStep === true && (
                <div className="mt-5 ml-1">
                  <div className="flex items-center justify-between mb-4" onClick={removePromocode}>
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Minus />
                      </div>
                      {transactionSummaryData?.promoCodeDetails?.promoCodeApplied
                        ? `${transactionSummaryData?.promoCodeDetails?.promoCode} applied`
                        : "Promo Code "}
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:text-sm">
                        {transactionSummaryData?.promoCodeDetails?.promoCodeDiscount
                          ? transactionSummaryData?.promoCodeDetails?.promoCodeDiscount
                          : 0}
                      </div>
                      <div className="text-xs font-normal text-black lg:text-sm">INR</div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-5">
                    <div className="flex items-center text-xs font-normal text-black lg:text-sm">
                      <div className="mr-3">
                        <Minus />
                      </div>
                      HOP Coins
                    </div>
                    <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-base">
                        {hopCoinsApplied / 2}
                      </div>
                      <div className="text-xs font-normal text-black lg:font-bold lg:text-sm">
                        INR
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {isInward && (
            <div className="flex items-center justify-between mb-8">
              <div className="flex flex-col flex-1 -ml-2">
                <div className="flex items-center justify-between cursor-pointer hover:bg-gray-100">
                  <div className="flex items-center text-xs group-hover:text-[16px] transition-all font-bold text-black lg:text-sm">
                    <div className="mr-3 transition-all group-hover:-rotate-90 ">
                      <Equal size={30} />
                    </div>
                    They Recieve
                  </div>
                  <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                    <div className="text-xs font-bold text-black lg:text-base group-hover:text-[16px] transition-all">
                      {transactionSummaryData?.reciepient_recieve}
                    </div>
                    <div className="text-xs font-bold text-black lg:text-sm group-hover:text-[16px] transition-all">
                      INR
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isInward && (
            <div className="flex items-center justify-between mb-8">
              <div className="flex flex-col flex-1 -ml-2">
                <div className="flex items-center justify-between cursor-pointer hover:bg-gray-100">
                  <div className="flex items-center text-xs group-hover:text-[16px] transition-all font-bold text-black lg:text-sm">
                    <div className="mr-3 transition-all group-hover:-rotate-90 ">
                      <Equal size={40} />
                    </div>
                    You Pay
                  </div>
                  <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                    <div className="text-xs font-bold text-black lg:text-base group-hover:text-[16px] transition-all">
                      {transactionSummaryData?.user_send}
                    </div>
                    <div className="text-xs font-bold text-black lg:text-sm group-hover:text-[16px] transition-all">
                      {isInward ? "GBP" : "INR"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div id="promo" className="flex items-center justify-between mb-8">
            <div
              className="bg-grey-1350 w-[30px] transition-all h-[30px] rounded-full flex justify-center items-center -ml-2 cursor-pointer"
              onClick={removePromocode}
            >
              <div className="w-3 h-[2px] bg-grey-700" />
            </div>
            <div className="flex items-center justify-between flex-1 ml-5">
              <div className="text-xs font-bold transition-all lg:text-sm text-black group-hover:text-[16px]">
                {transactionSummaryData?.promoCodeDetails?.promoCodeApplied
                  ? `${transactionSummaryData?.promoCodeDetails?.promoCode} applied`
                  : "Promo Code "}
              </div>
              <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                <div className="text-xs font-bold lg:text-base text-black group-hover:text-[16px] transition-all">
                  {transactionSummaryData?.promoCodeDetails?.promoCodeDiscount
                    ? transactionSummaryData?.promoCodeDetails?.promoCodeDiscount
                    : 0}
                </div>
                <div className="text-xs font-bold lg:text-sm text-black group-hover:text-[16px] transition-all">
                  INR
                </div>
              </div>
            </div>
          </div>
          <div id="hopcoins" className="flex items-center justify-between mb-8">
            <div
              className="bg-grey-1350 w-[30px] transition-all h-[30px] rounded-full flex justify-center items-center -ml-2 cursor-pointer"
              onClick={() => setAppliedHopCoins(0)}
            >
              <div className="w-3 h-[2px] bg-grey-700" />
            </div>
            <div className="flex items-center justify-between flex-1 ml-5">
              <div className="text-xs font-bold transition-all lg:text-sm text-black group-hover:text-[16px]">
                {hopCoinsApplied} HOP Coins
              </div>
              <div className="flex items-center justify-between space-x-5 lg:space-x-16">
                <div className="text-xs font-bold lg:text-base text-black group-hover:text-[16px] transition-all">
                  {hopCoinsApplied / 2}
                </div>
                <div className="text-xs font-bold lg:text-sm text-black group-hover:text-[16px] transition-all">
                  INR
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {selectedDeliveryOption === "Express Delivery" && (
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-7">
              <div className="flex items-center justify-center w-4 h-4 text-white rounded-full bg-grey-850 ring-8 ring-grey-850">
                +
              </div>
              <div className="text-xs font-semibold text-black">Payment Mode (Express)</div>
            </div>
            <div className="flex items-center justify-between space-x-10">
              <div className="text-xs font-bold text-black">
                {transactionSummaryData?.conversion_rate?.toFixed(4)}
              </div>
              <div className="text-xs font-bold text-black">INR</div>
            </div>
          </div>
        )}
        <div className="bg-grey-250 h-[1px]"></div>
        <div ref={ref} className="items-center justify-between mt-5 mb-6 flex flex-wrap">
          <div className="text-sm md:text-[22px] font-bold text-black">Total Payable</div>
          <div className="flex items-center justify-between space-x-10">
            <div className="text-sm md:text-[22px] font-bold text-black">
              {formatNumber(transactionSummaryData?.total_pay)}
            </div>
            <div className="text-sm md:text-[22px] font-bold text-black">
              {isInward ? "GBP" : "INR"}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <Button
            id="dajjj-dsajj-djaqbh"
            bgColor={
              getSendMoneyErrorMessage(
                receiveCurrency === "USD" ? receiveCurrency : sendingCurrency,
                sendingAmount,
                error,
              ) !== "" || error
                ? "bg-grey-350"
                : "bg-primary"
            }
            fontStyle={{
              fontFamily: "",
              fontSize: "lg:text-lg text-sm",
              fontWeight: "font-bold",
              fontColor: "text-white",
            }}
            borderStyle={{
              borderRadius:
                "rounded-full border-2 border-white hover:border-primary hover:bg-white hover:border-2 hover:text-primary transition-all hover:border-primary",
            }}
            disabledClassName="hover:bg-grey-350 hover:text-white hover:border-grey-350"
            className={"lg:py-3 lg:px-28 px-5 py-2"}
            onClickHandler={onClickHandler}
            disabled={
              getSendMoneyErrorMessage(
                receiveCurrency === "USD" ? receiveCurrency : sendingCurrency,
                sendingAmount,
                error,
              ) !== "" ||
              purposeErrorInward ||
              error ||
              loading
            }
            isLoading={loading}
          >
            {loading ? "" : "Confirm"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PaymentDetailsCard;
